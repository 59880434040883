import React, {FC, useEffect, useState} from "react";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography
} from "@mui/material";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {adminOrganizationFindAll} from "../../api/services";
import {AdminOrganizationListProjection} from "../../api/types";
import {useHistory} from "react-router";
import {useAppContext} from "../../contexts/PermissionContext";
import {AdminOrganizationSingle} from "./AdminOrganizationSingle";


export const AdminOrganizations: FC<{

}> = ({

}) => {

  let { getQueryParam, removeQueryParam } = useAppContext()

  let router = useHistory()

  const [organizationSearch, setOrganizationSearch] = useState<string>("")

  const [organizations, setOrganizations] = useState<AdminOrganizationListProjection[]>()

  const [id, setId] = useState<string>()

  useEffect(() => {
    let id = getQueryParam("id")
    if (id) {
      setId(id)
    }
  }, [getQueryParam("id")]);

  const searchOrganization = () => {
    adminOrganizationFindAll({ name: organizationSearch, page: 0, perPage: 1000 }).then(d => {
      setOrganizations(d.data.content)
    })
  }

  return (
    <ContentWrapper title={"Organizations"}>
      { id ? (
        <>
          <Button variant={"outlined"} onClick={() => {
            removeQueryParam("id")
            setId(undefined)
          }}>Back</Button>
          <Divider sx={{ margin: "5px" }}/>
          <AdminOrganizationSingle id={id}/>
        </>
      ) : (
        <>
          <Card>
            <CardContent>
              <DialogSimpleFieldText label={"Search for organization"} value={organizationSearch} setValue={setOrganizationSearch} error={false}/>
              <Button disabled={!organizationSearch} onClick={searchOrganization}>Search</Button>
            </CardContent>
          </Card>
          { organizations && organizations.length > 0 && (
            <Card>
              <CardContent>
                { organizations.map(org => (
                  <Box>
                    <Typography sx={{ margin: "5px"}}>
                      { org.name }
                      <Button onClick={() => router.push(`/admin/organizations?id=${org.id}`)}>Go</Button>
                    </Typography>
                    <Divider/>
                  </Box>
                ))}
              </CardContent>
            </Card>
          )}
        </>
      )}

    </ContentWrapper>
  )
}