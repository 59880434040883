import React, {FC, useEffect, useState} from "react";
import {LayoutWithSidebar, LayoutWithSidebarWrapper, sidebarStyles} from "../Reusable/LayoutWithSidebars";
import {CreatorListDataFull} from "../../api/types";
import {Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {GridComponent} from "../Reusable/GridComponent";
import {creatorsGetAll, mediaStorageGetList} from "../../api/services";
import {SidebarItemSummary} from "../Reusable/SidebarItemSummary";
import {Button} from "@mui/material";
import {useHistory} from "react-router";
import {ContentLayout} from "../Reusable/ContentLayout";
import {
  ContesterDataTable,
  ContesterDataTableNumberedList,
  ContesterDataTableState
} from "../Reusable/ContesterDataTable";

export const Creators: FC<{

}> = ({

}) => {

  const [selectedCreator, setSelectedCreator] = useState<CreatorListDataFull>(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  useEffect(() => {
    setSidebarOpen(Boolean(selectedCreator))
  }, [selectedCreator])

  useEffect(() => {
    if (!sidebarOpen) {
      setSelectedCreator(null)
    }
  }, [sidebarOpen])

  // @ts-ignore
  const [context, setContext] = useState({
    refreshGrid: () => {}
  })

  return (
    <LayoutWithSidebar
      main={<CreatorsTable
        selectedCreator={selectedCreator}
        setSelectedCreator={setSelectedCreator}
        context={context}
      />}
      sidebar={<CreatorsGridSidebar
        selectedCreator={selectedCreator}
        setSelectedCreator={setSelectedCreator}
        context={context}
      />}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    />
  )
}


export const CreatorsTable: FC<{
  selectedCreator: CreatorListDataFull,
  setSelectedCreator: React.Dispatch<React.SetStateAction<CreatorListDataFull>>,
  context: {
    refreshGrid: () => void,
  }
}> = ({
  selectedCreator,
  setSelectedCreator,
  context,
}) => {


  const [tab, setTab] = useState('active')

  const fetch = (page, perPage, sortColumn, sortDirection) => {
    return mediaStorageGetList({ page: page, perPage: perPage }).then(d => d.data)
  }
  const [tableState, setTableState] = useState<ContesterDataTableState>({
    count: 0, data: [],
    fetch: fetch,
    columns: ["id", "label"],
    page: 0,
    perPage: 10,
    sortColumn: undefined,
    sortDirection: undefined
  })

  return (
      <ContentLayout controls={<></>}>
        <ContesterDataTable
          state={tableState}
          setState={setTableState}
          />
      </ContentLayout>
    )
}




export const CreatorsGridComponent: FC<{
  item: CreatorListDataFull,
  selected: boolean,
}> = ({
  item,
  selected
}) => {

  return (
    <GridComponent thumbnail={item.picture} label={item.name} selected={selected}/>
  )
}

export const CreatorsGridSidebar: FC<{
  selectedCreator: CreatorListDataFull,
  setSelectedCreator: React.Dispatch<React.SetStateAction<CreatorListDataFull>>,
  context: {
    refreshGrid: () => void,
  }
}> = ({
  selectedCreator,
  setSelectedCreator,
  context,
}) => {

  let router = useHistory()

  return (
    <LayoutWithSidebarWrapper>
      { selectedCreator && (
        <>
          <SidebarItemSummary name={selectedCreator.name}/>
          <Divider style={sidebarStyles.divider}/>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Published experiences
                  </TableCell>
                  <TableCell>
                    {selectedCreator.statistics?.publishedExperiencesTotal ?? "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Unique visitors
                  </TableCell>
                  <TableCell>
                    {selectedCreator.statistics?.statistics?.visitors?.displayValue ?? "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Avg time spent
                  </TableCell>
                  <TableCell>
                    {selectedCreator.statistics?.statistics?.averageTimeSpent?.displayValue ?? "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Avg CTR
                  </TableCell>
                  <TableCell>
                    {selectedCreator.statistics?.statistics?.clickThroughRate?.displayValue ?? "0"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider style={sidebarStyles.divider}/>
          <Button style={sidebarStyles.mainButton} variant={"contained"} disabled={!selectedCreator.statistics} onClick={() => { router.push(`/creators/list?id=${selectedCreator.userId}`) }}>Analytics</Button>
        </>
      )}
    </LayoutWithSidebarWrapper>
  )
}