import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  editOrganizationProfileDataThunk,
  getActiveOrganizationThunk,
  getAvailableOrganizationsThunk,
} from '../../../redux/slices/organizationSlice'

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import { RootState } from 'src/redux/store'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import {OrganizationCreateAdditional} from "./OrganizationCreateAdditional";
import {SwitchOrganizationDropdown} from "./SwitchOrganizationDropdown";
import {useAppContext} from "../../../contexts/PermissionContext";

function OrganizationData() {
  const dispatch = useDispatch()


  const { permissions } = useAppContext()

  const organizationProfileData = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const authEmail = useSelector(
    (state: RootState) => state.authReducer.email
  )

  const [isEditing, setIsEditing] = useState(false)

  const [organizationName, setOrganizationName] = useState<string>(
    organizationProfileData?.name,
  )

  const wrapperRef = useRef(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist()
    setOrganizationName(event.target.value)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setOrganizationName(event.target.value)
      handleRename()
    }
  }

  const handleRename = async (): Promise<void> => {
    try {
      if (!organizationName) {
        setOrganizationName(organizationProfileData.name)
        return
      }
      const formData = {
        name: organizationName,
      }
      await dispatch(editOrganizationProfileDataThunk(formData))
      await dispatch(getActiveOrganizationThunk())
      await dispatch(getAvailableOrganizationsThunk())
    } catch (err) {
      console.error(err)
    }
    setIsEditing(false)
  }

  useEffect(() => {
    setOrganizationName(organizationProfileData?.name)
  }, [organizationProfileData])

  return (
    <>
      <ContentWrapper title="User Details - Management">
        <Card>

          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginRight="30px"
          >
            <SwitchOrganizationDropdown/>
            { permissions?.organization?.updateOrganizationProfile && (
              <>
                {!isEditing ? (
                  <Button
                    variant="text"
                    onClick={() => setIsEditing(true)}
                    startIcon={<EditTwoToneIcon />}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button onClick={handleRename}>Done</Button>
                )}
              </>
            )}
          </Box>

          <CardContent>
            <List sx={{ px: 2 }}>
              <Divider component="li" />
              <ListItem sx={{ py: 1.5 }}>
                <ListItemText primary={"Id"} primaryTypographyProps={{ variant: 'subtitle2' }}/>
                <Typography variant="subtitle2" color="text.secondary">
                  <Box
                    px={2}
                    pt={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      color="inherit"
                      variant="subtitle2"
                    >
                      {organizationProfileData?.id}
                    </Typography>
                  </Box>
                </Typography>
              </ListItem>
              <ListItem sx={{ py: 1.5 }}>
                <ListItemText
                  primary="Name"
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                />
                <Typography variant="subtitle2" color="text.primary">
                  <Box
                    px={2}
                    pt={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {isEditing ? (
                      <TextField
                        value={organizationName}
                        size="small"
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        ref={wrapperRef}
                        variant="outlined"
                        margin="dense"
                      />
                    ) : (
                      <Typography
                        color="inherit"
                        variant="h4"
                      >
                        {organizationProfileData?.name?.trim()
                          ? organizationProfileData?.name
                          : authEmail}
                      </Typography>
                    )}
                  </Box>
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <OrganizationCreateAdditional/>
      </ContentWrapper>
    </>
  )
}

export default OrganizationData
