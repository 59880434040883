import {DashboardCharts, DashboardOverallStatistics} from '../../api/types'
import {useAppContext} from "../../contexts/PermissionContext";
import {
  ConversionDataChart,
  EmailEventsDataChart,
  PageViewsDataChart, SalesDataChart,
  SocialDataChart,
  SubscriptionsDataChart,
  TimeSpentDataChart,
  VisitorsDataChart
} from "./Charts";
import {VisitorPieCharts} from "./VisitorPieCharts";
interface Props {
  overallStatistics: DashboardOverallStatistics,
  charts?: DashboardCharts,
}
const SummaryOverview = ({ overallStatistics, charts }: Props) => {
  const { permissions } = useAppContext()
  return (
    <>
      { charts && (
        <>
          <VisitorsDataChart data={charts} />
          <TimeSpentDataChart data={charts} />
          <PageViewsDataChart data={charts} />
          { (permissions?.organization?.sales || permissions?.creatorManagement?.sales) && (
            <>
              <SalesDataChart data={charts} liveStreamSales={false} />
              <ConversionDataChart data={charts} />
            </>
          )}
          <SubscriptionsDataChart data={charts} uniqueSubscribers={false} />
          <EmailEventsDataChart data={charts} />
          <SocialDataChart data={charts} />
          <VisitorPieCharts charts={charts} md={4}/>
        </>
      )}
    </>
  )
}

export default SummaryOverview
