import React, {FC, useEffect, useState} from "react";
import {useAppContext} from "../../contexts/PermissionContext";
import {ShareableUserData} from "../../api/types";
import {experienceSharesGetShareableUsers, sequencePollGetAvailableOrigins} from "../../api/services";
import {Box, Paper, Typography} from "@mui/material";
import {GridFilterControlAutocomplete} from "../Reusable/GridFilterControl";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setOriginHint} from "../../redux/slices/sequenceSlice";

export const ExperienceLinkSelection: FC<{
  link: ShareableUserData,
  setLink: React.Dispatch<React.SetStateAction<ShareableUserData>>,
  linkRequired: Boolean,
}> = ({
  link,
  setLink,
  linkRequired,
}) => {

  const dispatch = useDispatch()

  const { permissions } = useAppContext()

  const [linkableUsers, setLinkableUsers] = useState<ShareableUserData[]>()
  const [linkableOrigins, setLinkableOrigins] = useState<string[]>()
  const [organizationAvailableOrigins, setOrganizationAvailableOrigins] = useState<string[]>()

  const sequencePoll = useSelector((state: RootState) => state.sequenceReducer)

  useEffect(() => {
    if (!linkableUsers && permissions?.experience?.shareExperience) {
      experienceSharesGetShareableUsers().then(d => setLinkableUsers(d.data))
    }
  }, [linkableUsers, permissions])

  useEffect(() => {
    if (linkableUsers) {

      if (link) {
        setLinkableOrigins(link.origins)
        if (link.origins.length > 0) {
          dispatch(setOriginHint(sequencePoll.originHint ?? link.origins[0]))
        }
      } else {
        setLinkableOrigins(organizationAvailableOrigins)
        if (linkRequired && linkableUsers.length == 1) {
          setLink(linkableUsers[0])
        }
      }
    }
  }, [link, linkableUsers])

  useEffect(() => {

    if (!organizationAvailableOrigins && permissions.overall?.organizationCreator) {
      sequencePollGetAvailableOrigins().then(d => setOrganizationAvailableOrigins(d.data))
    }
  }, [organizationAvailableOrigins])

  const render = Boolean((linkableUsers && linkableUsers.length > 0) || (linkableOrigins && linkableOrigins.length > 1))

  return (
    <>
      { render && (
        <Paper elevation={0}>

          { linkableUsers && linkableUsers.length > 0 && (
            <Box>
              <GridFilterControlAutocomplete filter={{
                visible: true,
                name: "experienceLink",
                title: "Select advertiser",
                active: link,
                valueLabel: (l) => `${l.organizationName}`,
                values: linkableUsers,
                valueId: (l) => l.organizationId,
                component: (value) => <Typography>{value.organizationName}</Typography>,
                props: {
                  sx: { maxWidth: "100%" }
                }
              }}
              onFilter={(name, value) => {
                setLink(linkableUsers.find(l => l.organizationId === value))
              }}
              disabled={linkRequired && linkableUsers.length == 1}
              />
              { linkRequired && linkableUsers.length > 1 && (
                <Typography sx={{ fontSize: "10px", marginLeft:"14px", marginTop: "5px", color:"rgba(34, 51, 84, 0.7)" }}>Link selection required</Typography>
              )}
            </Box>
          )}
          { linkableOrigins && linkableOrigins.length > 0 && (
            <Box sx={{ marginTop: "10px" }}>
              <GridFilterControlAutocomplete filter={{
                visible: true,
                name: "originHint",
                title: "Select domain",
                active: sequencePoll.originHint,
                valueLabel: (l) => l,
                values: linkableOrigins,
                valueId: (l) => l,
                component: (value) => <Typography>{value}</Typography>,
                props: {
                  sx: { maxWidth: "100%" }
                }
              }} onFilter={(name, value) => {
                dispatch(setOriginHint(value))
              }}
              disabled={linkableOrigins.length == 1} />
            </Box>
          )}
        </Paper>
      )}
    </>
  )
}