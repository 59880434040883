import { HighlightOff } from '@mui/icons-material'
import { useState } from 'react'
import {
  DashboardCharts,
  DashboardData, DashboardGetCreatorDataByRangeQueryParams,
} from '../../../api/types'
import { FilterType, TableType } from '../../../pages/Dashboard'
import FullList from '../../Dashboard/FullList/FullList'
import UserChoiceTable from '../../Dashboard/Tables/UserChoiceTable'
import './SequencePollAnalytics.css'
import {useAppContext} from "../../../contexts/PermissionContext";
import {Box} from "@mui/material";
import {
  ActiveUsersDataChart,
  ConversionDataChart, EmailEventsDataChart,
  PageViewsDataChart,
  SalesDataChart, SocialDataChart, SubscriptionsDataChart,
  TimeSpentDataChart,
  VisitorsDataChart
} from "../../Dashboard/Charts";
import {VisitorPieCharts} from "../../Dashboard/VisitorPieCharts";
import {DateRangeSummaryFilter} from "../../Dashboard/DateRangeSummaryFilter";

interface Props {
  analyticsData: DashboardData
  lastHourChartsData: DashboardCharts
  chartsData: DashboardCharts
  filterData: DashboardGetCreatorDataByRangeQueryParams
  handleFilterDataChange: (type: FilterType, value: any) => void
  showCloseButton?: boolean
  closeAnalytics?: () => void
}

const SequencePollAnalytics = ({
  analyticsData,
  lastHourChartsData,
  chartsData,
  filterData,
  handleFilterDataChange,
  showCloseButton,
  closeAnalytics,
}: Props) => {
  const [showFullList, setShowFullList] = useState<TableType>(undefined)
  const { permissions } = useAppContext()
  return (
    <>
      {showFullList ? (
        <FullList
          experiences={analyticsData?.topPerformingExperiences}
          setShowFullList={setShowFullList}
          tableType={showFullList}
          userChoices={analyticsData?.topUserChoices}
        />
      ) : (
        <>

          {lastHourChartsData && (
            <Box sx={{marginTop: "10px"}}>
              <ActiveUsersDataChart data={lastHourChartsData} />
            </Box>
          )}
          <div className="analytics-header">
            <DateRangeSummaryFilter
              filterData={filterData}
              onSelected={(range) => {
                handleFilterDataChange('TIMERANGE', range)
              }}
            />
            {showCloseButton && (
              <div className="close-wrapper" onClick={closeAnalytics}>
                <HighlightOff />
              </div>
            )}
          </div>

          { chartsData && (
            <>
              <VisitorsDataChart data={chartsData} />
              <TimeSpentDataChart data={chartsData} />
              <PageViewsDataChart data={chartsData} />
              { (permissions?.organization?.sales || permissions?.creatorManagement?.sales) && (
                <>
                  <SalesDataChart data={chartsData} liveStreamSales={true} />
                  <ConversionDataChart data={chartsData} />
                </>
              )}
              <SubscriptionsDataChart data={chartsData} uniqueSubscribers={true} />
              <EmailEventsDataChart data={chartsData} />
              <SocialDataChart data={chartsData} />
              <VisitorPieCharts charts={chartsData} md={12}/>
            </>
          )}

          <UserChoiceTable
            userChoices={analyticsData?.topUserChoices}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
        </>
      )}
    </>
  )
}

export default SequencePollAnalytics
