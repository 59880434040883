import {FC} from "react";
import {DashboardCharts} from "../../api/types";
import {ChartBlock, ChartLayout} from "./ChartBlock";
import {DataChartDefinition} from "../Charts/DataChart";

export const ActiveUsersDataChart: FC<{
    data: DashboardCharts,
    layout?: ChartLayout,
}> = ({
  data,
  layout = "Standard",
}) => {
    return (
        <ChartBlock
            layout={layout}
            definition={{
                data: data,
                type: 'area',
                title: 'Active users',
                productGrouping: 'Sum',
                yAxis: {
                    show: false,
                    type: 'Absolute',
                    scale: 'Standard',
                },
                series: [
                    {
                        aggregateType: 'PING',
                        title: 'Active users',
                        currentValueAnnotation: 'Current users: {y}',
                        type: 'sessionCount',
                    }
                ]
            }}
            summaryValues={[]}
        />
    )
}

export const TimeSpentDataChart: FC<{
  data: DashboardCharts,
  layout?: ChartLayout,
}> = ({
  data,
  layout = "Standard",
}) => {
  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Time spent',
        productGrouping: 'Average',
        yAxis: {
          show: false,
          type: 'Time',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'METRICS_SESSIONS_DURATION_AVERAGE',
            type: 'value',
            title: 'Average time spent',
          }
        ]
      }}
      summaryValues={[
        {
          title: 'Total time viewed',
          value: data.commonValues.sessionsTotalTimeSpent.displayValue
        },
        {
          title: 'Avg time viewed',
          value: data.commonValues.sessionsAverageTimeSpent.displayValue
        },
      ]}/>
  )
}

export const PageViewsDataChart: FC<{
  data: DashboardCharts,
  layout?: ChartLayout,
}> = ({
        data,
  layout = "Standard",
      }) => {
  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Page views',
        productGrouping: 'Sum',
        yAxis: {
          show: false,
          type: 'Absolute',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'DIRECT_LINK_CLICK',
            type: 'value',
            title: 'Page views',
          }
        ]
      }}
      summaryValues={[
        {
          title: 'Page views',
          value: data.commonValues.lastCardClicksTotal.displayValue
        },
        {
          title: 'CTR',
          value: data.commonValues.sessionsClickThroughRate.displayValue
        },
      ]}/>
  )
}

export const SocialDataChart: FC<{
  data: DashboardCharts,
  layout?: ChartLayout,
}> = ({
        data,
  layout = "Standard",
      }) => {
  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Social interactions',
        productGrouping: 'Sum',
        yAxis: {
          show: false,
          type: 'Absolute',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'SOCIAL_LIKE',
            type: 'value',
            title: 'Likes',
          },
          {
            aggregateType: 'SOCIAL_SHARE',
            type: 'value',
            title: 'Shares',
          },
          {
            aggregateType: 'LIVE_CHAT_MESSAGE_SENT',
            type: 'value',
            title: 'Messages',
          },
        ]
      }}
      summaryValues={[
        {
          title: 'Messages',
          value: data.commonValues.socialMessagesTotalCount.displayValue
        },
        {
          title: 'Likes',
          value: data.commonValues.socialLikesTotalCount.displayValue
        },
        {
          title: 'Shares',
          value: data.commonValues.socialSharesTotalCount.displayValue
        },
      ]}/>
  )
}

export const VisitorsDataChart: FC<{
  data: DashboardCharts,
  layout?: ChartLayout,
}> = ({
        data,
  layout = "Standard",
      }) => {
  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Visitors',
        productGrouping: 'Sum',
        yAxis: {
          show: false,
          type: 'Absolute',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'METRICS_SESSIONS_COUNT_NEW',
            type: 'value',
            title: 'New visitors',
          },
          {
            aggregateType: 'METRICS_SESSIONS_COUNT_RETURNING',
            type: 'value',
            title: 'Returning visitors',
          },
        ]
      }}
      summaryValues={[
        {
          title: 'Visitors',
          value: data.commonValues.visitorsTotal.displayValue
        },
        {
          title: 'Return rate',
          value: data.commonValues.visitorsReturnRate.displayValue
        },
        {
          title: 'Engagement rate',
          value: data.commonValues.sessionsEngagementRate.displayValue
        },
      ]}/>
  )
}

export const SubscriptionsDataChart: FC<{
  data: DashboardCharts,
  uniqueSubscribers: boolean,
  layout?: ChartLayout,
}> = ({
        data,
        uniqueSubscribers = false,
  layout = "Standard",
      }) => {

  let summaryValues = [
    {
      title: 'Total subscribers',
      value: data.commonValues.subscriptionsTotal.displayValue
    },
    {
      title: 'Subscription rate',
      value: data.commonValues.sessionsSubscriptionRate.displayValue
    },
  ]

  if (uniqueSubscribers) {
    summaryValues = [
      {
        title: 'Total subscribers',
        value: data.commonValues.subscriptionsTotal.displayValue
      },
      {
        title: 'Unique subscribers',
        value: data.commonValues.subscriptionsLiveStream.displayValue,
      },
      {
        title: 'Subscription rate',
        value: data.commonValues.sessionsSubscriptionRate.displayValue
      },
    ]
  }

  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Subscribers',
        productGrouping: 'Sum',
        yAxis: {
          show: false,
          type: 'Absolute',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'METRICS_SESSIONS_SUBSCRIPTION_ANY',
            type: 'value',
            title: 'Total subscribers',
          },
        ]
      }}
      summaryValues={summaryValues}/>
  )
}

export const SalesDataChart: FC<{
  data: DashboardCharts,
  liveStreamSales: boolean,
  layout?: ChartLayout,
}> = ({
  data,
  liveStreamSales,
  layout = "Standard",
      }) => {

  let definition: DataChartDefinition = {
    data: data,
    type: 'area',
    title: 'Sales',
    productGrouping: 'Sum',
    yAxis: {
      show: false,
      type: 'Absolute',
      scale: 'Standard',
    },
    series: [
      {
        aggregateType: 'AFFILIATE_AWIN_EXPERIENCE_SALE',
        type: 'value',
        title: 'Total sales',
      },
    ]
  }

  let summaryValues = [
    {
      title: 'Total sales',
      value: data.commonValues.salesTotal.displayValue
    },
    {
      title: 'AOV',
      value: data.commonValues.salesAverageOrderValue.displayValue
    },
  ]

  if (liveStreamSales) {
    definition.series.push(
      {
        aggregateType: 'AFFILIATE_AWIN_LIVESTREAM_SALE',
        type: 'value',
        title: 'Livestream sales',
      },
    )

    summaryValues = [
      {
        title: 'Total sales',
        value: data.commonValues.salesTotal.displayValue
      },
      {
        title: 'After live sales',
        value: data.commonValues.salesAfterLiveStream.displayValue
      },
      {
        title: 'AOV',
        value: data.commonValues.salesAverageOrderValue.displayValue
      },
    ]
  }

  return (
    <ChartBlock
      layout={layout}
      definition={definition}
      summaryValues={summaryValues}/>
  )
}

export const ConversionDataChart: FC<{
  data: DashboardCharts,
  layout?: ChartLayout,
}> = ({
        data,
  layout = "Standard",
      }) => {
  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Conversion over time',
        productGrouping: 'Sum',
        yAxis: {
          show: false,
          type: 'Absolute',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'AFFILIATE_AWIN_EXPERIENCE_SALE',
            type: 'count',
            title: 'Conversions',
          },
          {
            aggregateType: 'AFFILIATE_AWIN_GLOBAL_EXPERIENCE_CLICK',
            type: 'count',
            title: 'Affiliate clicks'
          },
        ]
      }}
      summaryValues={[
        {
          title: 'Affiliate clicks',
          value: data.commonValues.salesClicksTotal.displayValue,
        },
        {
          title: 'Conversions',
          value: data.commonValues.salesTransactionsTotal.displayValue,
        },
        {
          title: 'CVR overall',
          value: data.commonValues.salesConversionRate.displayValue
        },
      ]}/>
  )
}

export const EmailEventsDataChart: FC<{
  data: DashboardCharts,
  layout?: ChartLayout,
}> = ({
        data,
  layout = "Standard",
      }) => {
  return (
    <ChartBlock
      layout={layout}
      definition={{
        data: data,
        type: 'area',
        title: 'Email events',
        productGrouping: 'Sum',
        yAxis: {
          show: false,
          type: 'Absolute',
          scale: 'Standard',
        },
        series: [
          {
            aggregateType: 'EMAIL_TRACKER_GLOBAL_EMAIL_OPEN',
            type: 'value',
            title: 'Email opens',
          },
          {
            aggregateType: 'EMAIL_TRACKER_GLOBAL_LINK_CLICK',
            type: 'value',
            title: 'Link clicks',
          },
        ]
      }}
      summaryValues={[
        {
          title: 'Total emails opened',
          value: data.commonValues.emailEventsEmailOpensTotalCount.displayValue
        },
        {
          title: 'Unique emails opened',
          value: data.commonValues.emailEventsEmailOpensUniqueTotalCount.displayValue
        },
        {
          title: 'Unique email link clicks',
          value: data.commonValues.emailEventsLinkClicksUniqueTotalCount.displayValue
        },
      ]}/>
  )
}