import {FC, useState} from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {useAppContext} from "../../../contexts/PermissionContext";
import {Button} from "@mui/material";
import {userCreateAdditionalOrganization} from "../../../api/services";
import {toast} from "react-toastify";


export const OrganizationCreateAdditional: FC<{

}> = ({

}) => {

  let { permissions } = useAppContext()

  const [loading, setLoading] = useState(false)

  const createOrganizationClicked = async () => {
    setLoading(true)
    await userCreateAdditionalOrganization()
    toast.success("Organization created successfully")
    window.location.reload()
  }

  return (
    <>
      { permissions?.admin?.createAdditionalOrganization && (
        <Card>
          <CardContent>
            <Typography variant={"h3"}>Create additional organizations</Typography>
            <Typography variant={"body1"}>
              Your organization type allows creating additional sub-organizations.
            </Typography>
            <Typography variant={"body1"}>
              Clicking the button below will create one and switch you over to it.
            </Typography>
            <Typography variant={"body1"}>
              Sub-organizations fall under the same subscription agreement.
            </Typography>
            <Button sx={{ marginTop: "10px" }} disabled={loading} variant={"contained"} onClick={createOrganizationClicked}>Create additional organization</Button>
          </CardContent>
        </Card>
      )}
    </>
  )
}