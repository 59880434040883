import React, {FC, useEffect, useState} from "react";
import {LiveStreamDestinationData, LiveStreamListData} from "../../api/types";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {DialogConfirmation} from "../Reusable/DialogConfirmation";
import {DialogSimple} from "../Reusable/DialogSimple";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {liveStreamsCreateDestination, liveStreamsDeleteDestination} from "../../api/services";
import {toast} from "react-toastify";
import AddIcon from '@mui/icons-material/Add';


export const DialogLiveStreamDestinations: FC<{
  liveStream: LiveStreamListData,
  destinations: LiveStreamDestinationData[],
  setDestinations: React.Dispatch<React.SetStateAction<LiveStreamDestinationData[]>>,
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({
  liveStream,
  destinations,
  setDestinations,
  open,
  setOpen,
}) => {

  const [editableDestination, setEditableDestination] = useState<LiveStreamDestinationData>()
  const [deletableDestination, setDeletableDestination] = useState<LiveStreamDestinationData>()

  const [editName, setEditName] = useState<string>("")
  const [editBroadcastUrl, setEditBroadcastUrl] = useState<string>("")
  const [editBroadcastKey, setEditBroadcastKey] = useState<string>("")

  useEffect(() => {
    setEditName(editableDestination?.name ?? "")
    setEditBroadcastUrl(editableDestination?.broadcastUrl ?? "")
    setEditBroadcastKey(editableDestination?.broadcastKey ?? "")

  }, [editableDestination])

  const deleteDestination = () => {
    liveStreamsDeleteDestination(liveStream.id, deletableDestination.id).then(() => {
      setDestinations(destinations.filter(d => d.id != deletableDestination.id))
      toast.success("Destination deleted")
      setDeletableDestination(undefined)
    })
  }

  const dismissDelete = () => {
    setDeletableDestination(undefined)
  }

  const dismisDialog = () => {
    setDeletableDestination(undefined)
    setEditableDestination(undefined)
    setOpen(false)
  }

  const saveEditableDestination = async () => {
    let tempDestinations = destinations
    if (editableDestination.id) {
      await liveStreamsDeleteDestination(liveStream.id, editableDestination.id)
      tempDestinations = tempDestinations.filter(d => d.id != editableDestination.id)
    }
    let destination = await liveStreamsCreateDestination(liveStream.id, {
      name: editName,
      broadcastKey: editBroadcastKey,
      broadcastUrl: editBroadcastUrl
    })
    tempDestinations = tempDestinations.concat(destination.data)
    setDestinations([
      ...tempDestinations
    ])
    setEditableDestination(undefined)
    toast.success("Destination saved")
  }

  // @ts-ignore
  return (
    <>
      <DialogConfirmation
        title={"Are you sure?"}
        description={"Are you sure you want to delete this destination?"}
        yesTitle={"Delete"}
        noTitle={"Cancel"}
        open={Boolean(deletableDestination)}
        onPressYes={deleteDestination}
        onPressNo={dismissDelete}
        onDismiss={dismissDelete}
      />
      <DialogSimple title={`Multicasting destinations (${destinations?.length ?? 0}/5)`} open={open} onDismiss={dismisDialog} maxWidth={"sm"} content={
        <Box>
          { editableDestination ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                { editableDestination?.id ? (
                  <Typography>
                    Edit existing destination
                  </Typography>
                  ) : (
                  <Typography>
                    Create a new destination
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <DialogSimpleFieldText
                  label={"Name"}
                  value={editName}
                  setValue={setEditName}
                  error={false} />
              </Grid>
              <Grid item xs={12}>
                <DialogSimpleFieldText
                  label={"Stream url"}
                  value={editBroadcastUrl}
                  setValue={setEditBroadcastUrl}
                  error={false} />
              </Grid>
              <Grid item xs={12}>
                <DialogSimpleFieldText
                  label={"Stream key"}
                  value={editBroadcastKey}
                  setValue={setEditBroadcastKey}
                  error={false} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Typography variant={"body1"} color={"secondary"} sx={{ marginBottom: "10px" }}>
                You can simultaneously stream to up to 5 additional RTMPS destinations
              </Typography>


              <Button startIcon={<AddIcon/>} variant={"contained"} color={"primary"} sx={{ marginBottom: "10px" }} onClick={() => {
                //@ts-ignore
                setEditableDestination({})
              }}>Add destination</Button>

              { destinations && destinations.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    {/*<TableHead>*/}
                    {/*  <TableRow>*/}
                    {/*    <TableCell>*/}
                    {/*      Name*/}
                    {/*    </TableCell>*/}
                    {/*    <TableCell>*/}

                    {/*    </TableCell>*/}
                    {/*  </TableRow>*/}
                    {/*</TableHead>*/}
                    <TableBody>
                      { destinations.map(destination => (
                        <TableRow key={`destination-${destination.id}`}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {destination.name}
                          </TableCell>
                          <TableCell align={"right"}>
                            <Button onClick={() => setEditableDestination(destination)}>Edit</Button>
                            <Button onClick={() => setDeletableDestination(destination)} color={"error"}>Delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}

        </Box>
      } actions={
        <>
          { editableDestination ? (
            <>
              <Button variant={"text"} color={"success"} disabled={!editName || !editBroadcastUrl || !editBroadcastKey} onClick={saveEditableDestination}>Save</Button>
              <Button variant={"text"} color={"primary"} onClick={() => setEditableDestination(undefined) }>Back</Button>
            </>
            ) : (
            <>
              {/*<Button variant={"contained"} color={"primary"} onClick={() => setOpen(false) }>Cancel</Button>*/}
            </>
          )}
        </>
      }/>
    </>
  )
}