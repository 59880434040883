import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  Slide,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'
import React, {
  ChangeEvent,
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelPendingInvitationThunk,
  getOrganizationMembersThunk,
  getPendingInvitationsThunk,
  removeMemberOrganizationThunk,
  setAdminOrganizationThunk,
  unsetAdminOrganizationThunk,
} from 'src/redux/slices/organizationSlice'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import { experimentalStyled } from '@mui/material/styles'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import InvitationsTable from 'src/components/Account/OrganizationData/InvitationsTable'

import { RootState } from 'src/redux/store'
import { handleThunkPromiseToast } from 'src/utils/toast.config'
import Creators from '../Creators/Creators'
import {
  OrganizationMemberData,
  OrganizationPendingInvitation,
} from '../../../api/types'
import { organizationInviteMember } from '../../../api/services'
import MemberInvitationForm from './MemberInvitationForm'

const DialogWrapper = experimentalStyled(Dialog)(
  () => `
        .MuiDialog-paper {
          overflow: visible;
        }
  `,
)

const AvatarError = experimentalStyled(Avatar)(
  ({ theme }) => `
        background-color: ${theme.colors.error.lighter};
        color: ${theme.colors.error.main};
        width: ${theme.spacing(12)};
        height: ${theme.spacing(12)};
  
        .MuiSvgIcon-root {
          font-size: ${theme.typography.pxToRem(45)};
        }
  `,
)

const ButtonError = experimentalStyled(Button)(
  ({ theme }) => `
       background: ${theme.colors.error.main};
       color: ${theme.palette.error.contrastText};
  
       &:hover {
          background: ${theme.colors.error.dark};
       }
      `,
)

export const TabsWrapper = experimentalStyled(Tabs)(
  ({ theme }) => `
      .MuiTabs-indicator {
        box-shadow: none;
        border: none;
        background-color: #424242;
      }
      .Mui-selected {
       background-color: #424242;
    }

      @media (max-width: ${theme.breakpoints.values.md}px) {
        .MuiTabs-scrollableX {
          overflow-x: auto !important;
        }
  
        .MuiTabs-indicator {
            box-shadow: none;
        }
      }
      `,
)

export const InviteButtonWrapper = experimentalStyled(Button)(
  () => `
    margin-left: 10px;
      `,
)

interface ResultsProps {}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const applyMembersPagination = (
  members: OrganizationMemberData[],
  page: number,
  limit: number,
): OrganizationMemberData[] => {
  return members?.slice(page * limit, page * limit + limit)
}

const applyInvitationsPagination = (
  invitations: OrganizationPendingInvitation[],
  page: number,
  limit: number,
): OrganizationPendingInvitation[] => {
  return invitations?.slice(page * limit, page * limit + limit)
}

interface TeamTab {
  value: string
  label: string
}

const OrganizationMembers: FC<ResultsProps> = ({ ...rest }) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()

  const invitations = useSelector(
    (state: RootState) => state.organizationReducer.pendingInvitations,
  )

  const members = useSelector(
    (state: RootState) => state.organizationReducer.members,
  )
  const teamPermissions = useSelector(
    (state: RootState) => state.organizationReducer.permissions.team,
  )
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [removeMemberId, setRemoveMemberId] = useState<string>('')
  const [cancelInvitationId, setCancelInvitationId] = useState<string>('')
  const [tabs, setTabs] = useState<TeamTab[]>([])
  const [currentTab, setCurrentTab] = useState<string>('')

  useEffect(() => {
    if (!members) {
      dispatch(getOrganizationMembersThunk())
    }
  }, [dispatch, members])

  useEffect(() => {
    if (!invitations && teamPermissions.viewInvitations) {
      dispatch(getPendingInvitationsThunk())
    }
  }, []);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  //   const filteredUsers = applyFilters(users, query, filters);
  const paginatedMembers = applyMembersPagination(members, page, limit)
  applyInvitationsPagination(invitations, page, limit)

  const [openConfirmRemove, setOpenConfirmRemove] = useState(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false)

  const closeConfirmRemove = () => {
    setOpenConfirmRemove(false)
    setRemoveMemberId('')
  }

  const closeConfirmCancel = () => {
    setOpenConfirmCancel(false)
    setCancelInvitationId('')
  }

  const handleSetAdmin = id => async (event: React.MouseEvent) => {
    await handleThunkPromiseToast(
      () => dispatch(setAdminOrganizationThunk(id)),
      'The member role is upgraded to Admin!',
      'success',
      'Failed to upgrade role to Admin',
      'error',
    )
    await dispatch(getOrganizationMembersThunk())
  }

  const handleUnsetAdmin = id => async (event: React.MouseEvent) => {
    await handleThunkPromiseToast(
      () => dispatch(unsetAdminOrganizationThunk(id)),
      'The Admin role is downgraded to Member!',
      'success',
      'Failed to downgrade role',
      'error',
    )
    dispatch(getOrganizationMembersThunk())
  }

  const handleSetRemoveMemberId = id => (event: React.MouseEvent) => {
    setOpenConfirmRemove(true)
    setRemoveMemberId(id)
  }

  const handleSetCancelInvitationId = id => {
    setOpenConfirmCancel(true)
    setCancelInvitationId(id)
  }

  const handleRemoveMember = id => async (event: React.MouseEvent) => {
    await handleThunkPromiseToast(
      () => dispatch(removeMemberOrganizationThunk(id)),
      'The member is successfully removed from your organization!',
      'info',
      'Failed to remove member from organization',
      'error',
    )
    setOpenConfirmRemove(false)
    setRemoveMemberId('')
  }

  const handleCancelInvitation = id => async (event: React.MouseEvent) => {
    await handleThunkPromiseToast(
      () => dispatch(cancelPendingInvitationThunk(id)),
      'Invitation deleted successfully',
      'info',
      'Failed to delete invitation',
      'error',
    )
    setOpenConfirmCancel(false)
    setCancelInvitationId('')
  }

  const setTabsBasedOnPermission = () => {
    const visibleTabs: TeamTab[] = []
    if (teamPermissions.viewMembers) {
      visibleTabs.push({
        value: 'members',
        label: 'Members',
      })
    }
    if (teamPermissions.viewCreators) {
      visibleTabs.push({
        value: 'creators',
        label: 'Creators',
      })
    }
    if (teamPermissions.viewInvitations) {
      visibleTabs.push({
        value: 'invitations',
        label: 'Invitations',
      })
    }
    setTabs(visibleTabs)
    setCurrentTab(visibleTabs[0].value)
  }

  useEffect(() => {
    setTabsBasedOnPermission()
  }, [teamPermissions])

  return (
    <ContentWrapper title="User Details - Management">
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor={'secondary'}
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
      </Box>
      <Card>
        {currentTab === 'members' &&
          (!paginatedMembers || paginatedMembers?.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                We couldn't find any members
              </Typography>
            </>
          ) : (
            <>
              <MemberInvitationForm
                label={'Invite members by email'}
                handleRefresh={() => dispatch(getPendingInvitationsThunk())}
                handleInvite={emails =>
                  organizationInviteMember({ emails: emails })
                }
                canInvite={teamPermissions.inviteMember}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      {/* <TableCell>{t('Verified')}</TableCell> */}
                      <TableCell>Role</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedMembers.map(member => {
                      return (
                        <TableRow hover key={member.id}>
                          <TableCell>
                            <Typography variant="h5" sx={{ wordBreak: "break-all" }}>{member.email}</Typography>
                          </TableCell>
                          {/* <TableCell>
                                  <Typography>
                                    {member.emailVerified
                                      ? 'Active'
                                      : 'Pending'}
                                  </Typography>
                                </TableCell> */}
                          <TableCell>
                            <Typography fontWeight="bold">
                              {member.type}
                              {member.type === 'Administrator' ? (
                                <>
                                  {teamPermissions.unsetAdmin && (
                                    <Tooltip title="Unset admin" arrow>
                                      <IconButton
                                        onClick={handleUnsetAdmin(member.id)}
                                        color="primary"
                                      >
                                        <ArrowDownwardIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                <>
                                  {teamPermissions.setAdmin && (
                                    <Tooltip title="Set admin" arrow>
                                      <IconButton
                                        onClick={handleSetAdmin(member.id)}
                                        color="primary"
                                      >
                                        <ArrowUpwardIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {teamPermissions.removeMember && (
                              <Typography noWrap>
                                <Tooltip title="Remove" arrow>
                                  <IconButton
                                    onClick={handleSetRemoveMemberId(member.id)}
                                    color="primary"
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={members.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          ))}
        {currentTab === 'invitations' &&
          (invitations.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                We couldn't find any invitations
              </Typography>
            </>
          ) : (
            <>
              <InvitationsTable
                invitations={invitations}
                type="Member"
                canCancelInvitation={teamPermissions.cancelInvitation}
                handleSetCancelInvitationId={handleSetCancelInvitationId}
              />
              <InvitationsTable
                invitations={invitations}
                type="Creator"
                canCancelInvitation={teamPermissions.cancelInvitation}
                handleSetCancelInvitationId={handleSetCancelInvitationId}
              />
            </>
          ))}
        {currentTab === 'creators' && (
          <Creators canInvite={teamPermissions.inviteCreator} />
        )}
      </Card>
      <DialogWrapper
        open={openConfirmRemove}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmRemove}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            Are you sure you want to remove this member?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmRemove}
            >
              Cancel
            </Button>
            <ButtonError
              onClick={handleRemoveMember(removeMemberId)}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              Delete
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <DialogWrapper
        open={openConfirmCancel}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmCancel}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            Are you sure you want to cancel this invitation?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmCancel}
            >
              Cancel
            </Button>
            <ButtonError
              onClick={handleCancelInvitation(cancelInvitationId)}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              Delete
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </ContentWrapper>
  )
}

export default OrganizationMembers
