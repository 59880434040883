import React, {FC, ReactNode} from "react";
import {TextField} from "@mui/material";


export const DialogSimpleFieldText: FC<{
  label: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  helperText?: string | ReactNode,
  error: boolean,
  errorText?: string,
  multiline?: boolean,
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
  onBlurCapture?: (e: React.FocusEvent<HTMLDivElement>) => void,
  variant?: "filled" | "outlined" | "standard",
  disabled?: boolean,
}> = ({
  label,
  value,
  setValue,
  helperText = "",
  error,
  errorText = "Field is required",
  multiline = false,
  onKeyDown,
  onBlurCapture,
  variant,
  disabled = false,
}) => {

  return (
    <TextField
      margin={"dense"}
      variant={variant ?? "standard"}
      maxRows={3}
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value) }
      onKeyDown={(e) => onKeyDown?.(e)}
      onBlurCapture={(e) => onBlurCapture?.(e)}
      type="text"
      fullWidth
      error={error}
      disabled={disabled}
      helperText={error ? errorText : helperText}
      multiline={multiline}
    />
  )
}