import { Card } from '@mui/material'
import { SetStateAction } from 'react'
import { TopUserChoice } from '../../../api/types'
import { TableType } from '../../../pages/Dashboard'
import { HighlightOff } from '@mui/icons-material'
import './SummaryTable.css'

interface Props {
  userChoices: TopUserChoice[]
  rowsToShow: number
  withHeading: boolean
  setShowFullList: React.Dispatch<SetStateAction<TableType>> | null
  type?: 'Regular' | 'Compact'
}

const UserChoiceTable = ({
  userChoices,
  rowsToShow,
  withHeading,
  setShowFullList,
  type = 'Regular',
}: Props) => {
  return (
    <div>
      <Card className="summary-table" m={2}>
        <table>
          <thead
            className={`summary-table-header ${
              !withHeading && 'sticky-header'
            }`}
          >
            {!withHeading && (
              <p
                className="table-close"
                onClick={() => setShowFullList?.(undefined)}
              >
                <HighlightOff />
              </p>
            )}
            <tr>
              <th>Top user choices</th>
              <th>Clicks</th>
              {type == 'Regular' && <th>Page</th>}
            </tr>
          </thead>
          <tbody>
            {userChoices?.slice(0, rowsToShow).map(choice => {
              return (
                <tr key={choice?.cardId} className={type}>
                  { choice.product ? (
                    <td style={type == 'Compact' ? { justifyContent: 'left' } : {}}>
                      <img
                        className="table-avatar-square"
                        src={choice.product.images[0]}
                      />
                      <div>
                        <div>{choice.product.title}</div>
                        <div style={{ fontSize: "14px" }}>
                          <span>
                            {choice.product.price}
                          </span>
                          { choice.product.oldPrice && (
                            <span style={{ textDecoration: "line-through"}}>{choice.product.oldPrice}</span>
                          )}
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td style={type == 'Compact' ? { justifyContent: 'left' } : {}}>
                      <img
                        className="table-avatar-square"
                        src={choice.cardImage}
                      />
                      { choice.tags?.length > 0 ? (
                          <div>{choice.tags.join(", ")}</div>
                      ) : choice?.directLinkLabel ? (
                          <div>{choice.directLinkLabel}</div>
                      ) : (
                          <div>{choice.campaignName}</div>
                      )}

                    </td>
                  )}
                  <td>{choice?.leads?.displayValue} clicks</td>
                  {type == 'Regular' && (
                    <td>
                      <a target="_blank" href={choice?.leadUrl}>
                        {choice?.leadUrl}
                      </a>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
          {withHeading && type == 'Regular' && userChoices?.length > rowsToShow && (
            <tfoot>
              <tr className="table-show-more">
                <td colSpan={5} onClick={() => setShowFullList?.('USER')}>
                  Show more
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  )
}

export default UserChoiceTable
