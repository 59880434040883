import React, {FC, useEffect, useState} from "react";
import {LiveStreamSingleData, LiveStreamUpdateRequest} from "../../api/types";
import {Grid} from "@mui/material";
import {Button} from "@mui/material";
import {InlineImageSelector} from "../Reusable/InlineImageSelector";
import {GalleryItem} from "../Gallery/helpers";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {liveStreamsCreate, liveStreamsUpdate} from "../../api/services";
import {DialogSimpleFieldDateTimePicker} from "../Reusable/DialogSimpleFieldDateTimePicker";
import {DialogSimpleFieldRadioGroup} from "../Reusable/DialogSimpleFieldRadioGroup";
import {DialogSimple} from "../Reusable/DialogSimple";
import {useAppContext} from "../../contexts/PermissionContext";


export const LiveStreamUpdateDialog: FC<
  {
    liveStream: LiveStreamSingleData | null,
    open: boolean,
    onSave: (LiveStream) => void,
    onDismiss: () => void,
  }> = ({ liveStream, open, onSave, onDismiss }) => {

  const [poster, setPoster] = useState<GalleryItem | null>(null)
  const [title, setTitle] = useState<string | null>(null)
  const [scheduled, setScheduled] = useState<number | null>(null)
  const [visibility, setVisibility] = useState<'Private' | 'Public' | null>(null)
  const [latencyMode, setLatencyMode] = useState<'Standard' | 'LowLatency' | null>(null)
  const [accessType, setAccessType] = useState<'Standard' | 'Extended' | null>(null)

  const [canSave, setCanSave] = useState(false)

  const { permissions } = useAppContext()

  const setValues = (liveStream) => {
    setPoster(liveStream?.poster)
    setTitle(liveStream?.title ?? "")
    setScheduled(liveStream?.scheduledAt as any * 1000)
    setVisibility(liveStream?.visibility ?? 'Public')
    setLatencyMode(liveStream?.latencyMode ?? 'Standard')
    setAccessType(liveStream?.accessType ?? 'Standard')
  }

  useEffect(() => {
    setValues(liveStream)
  }, [liveStream])

  useEffect(() => {
    setCanSave(Boolean(poster && title && scheduled && visibility && latencyMode && accessType))
  }, [poster, title, scheduled, visibility, latencyMode, accessType])

  const dismiss = () => {
    setValues(null)
    onDismiss()
  }

  const save = async () => {

    let request: LiveStreamUpdateRequest = {
      title: title,
      posterId: poster.id,
      // @ts-ignore
      scheduled: scheduled as any / 1000,
      visibility: visibility,
      accessType: accessType,
    }

    if (liveStream && liveStream.id) {
      let updated = await liveStreamsUpdate(liveStream.id, request)
      onSave(updated.data)
    } else {
      let created = await liveStreamsCreate({
        ...request,
        latencyMode: latencyMode,
      })
      onSave(created.data)
    }
    dismiss()
  }

  return (
    <DialogSimple
        open={open}
        onDismiss={dismiss}
        maxWidth={"sm"}
        title={
          <>
            { liveStream ? (
              <>
                Update live stream
              </>
            ) : (
              <>
                Create live stream
              </>
            )}
          </>
        }
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DialogSimpleFieldText label={"Title"} value={title} setValue={setTitle} error={false} />
              <DialogSimpleFieldDateTimePicker label={"Scheduled"} value={scheduled} setValue={setScheduled} error={scheduled === null } />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InlineImageSelector item={poster} setItem={setPoster} title={"Poster"} type={'image/*'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DialogSimpleFieldRadioGroup label={"Visibility"} value={visibility} setValue={setVisibility} values={[{ label: "Private (only visible in live room)", key: "Private"}, { label: "Public (visible to end users)", key: "Public" }]}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DialogSimpleFieldRadioGroup disabled={Boolean(liveStream?.id)} label={"Latency mode"} value={latencyMode} setValue={setLatencyMode} values={[{ label: "Standard (more stable)", key: "Standard"}, { label: "Low latency (less delay)", key: 'LowLatency' }]}/>
            </Grid>
            { permissions?.overall?.organizationCreator && (
              <Grid item xs={12} sm={6}>
                <DialogSimpleFieldRadioGroup disabled={Boolean(liveStream?.id)} label={"Access type"} value={accessType} setValue={setAccessType} values={[{ label: "Standard (only you can access)", key: "Standard"}, { label: "Extended (Accessible by organization members)", key: 'Extended' }]}/>
              </Grid>
            )}
          </Grid>
        }
        actions={
          <>
            <Button disabled={!canSave} onClick={save}>Save</Button>
            <Button onClick={dismiss}>Cancel</Button>
          </>
        }/>
  )
}