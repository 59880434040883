import { Card, Checkbox } from '@mui/material'
import styled from '@emotion/styled'
import Gauge from '../Dashboard/Templates/RoundChart'
import { CreatorStatistics } from 'src/api/types'
import LaunchIcon from '@mui/icons-material/Launch'
import { creatorsSetRequiresApproval } from '../../api/services'
import { toast } from 'react-toastify'
import React from 'react'
import { ApprovalStatusIcon } from '../Approval/ApprovalStatusIcon'


const CreatorAvatar = styled.img(() => ({
  minWidth: '45px',
  width: '45px',
  height: '45px',
  borderRadius: '50%',
  objectFit: 'cover',
}))

const TbodyStyled = styled.tbody(() => ({
  fontWeight: 'normal',
}))

const TheadStyled = styled.thead(() => ({
  zIndex: 2,
}))

interface Props {
  creators: CreatorStatistics[]
  setCreators: (creators: CreatorStatistics[]) => void
  setIsUserCardOpened?: (id: string) => void
}

export const CreatorsTable = ({
  creators,
  setCreators,
  setIsUserCardOpened,
}: Props) => {
  const onCreatorNeedsApprovalChecked = (creatorId, needsApproval) => {
    if (creatorId) {
      creatorsSetRequiresApproval({
        creatorId: creatorId,
        requiresApproval: needsApproval,
      }).then(() => {
        toast.success('Settings updated')
        creators.find(c => c.id === creatorId).needsApproval = needsApproval
        setCreators(creators)
      })
    }
  }

  return (
    <Card
      className="summary-table"
      sx={{ width: '100%', height: '100%', m: 2 }}
    >
      <div>
        <table>
          <TheadStyled className={`summary-table-header sticky-header`}>
            <tr>
              <th>Creator</th>
              <th>Subscribers</th>
              <th>Campaigns</th>
              <th>Unique visitors</th>
              {/*<th>Avg CTR</th>*/}
              <th>Conversion</th>
              <th>Sales</th>
              <th>Needs approval</th>
              <th>Status</th>
              <th></th>
            </tr>
          </TheadStyled>
          <TbodyStyled>
            {creators?.map(creator => {
              return (
                <tr key={creator.id}>
                  <td
                    className="summary-table-clickable"
                    onClick={() => setIsUserCardOpened(creator.id)}
                  >
                    <Gauge
                      circleRatio={0.8}
                      value={creator.score.current}
                      strokeWidth={10}
                      variant="SCORE"
                    >
                      <CreatorAvatar src={creator.profilePicture} />
                    </Gauge>
                    <span>{creator.displayName}</span>
                  </td>
                  <td>{creator.statistics.subscribers?.displayValue}</td>
                  <td>{creator.publishedExperiencesTotal}</td>
                  <td>{creator.statistics.visitors?.displayValue}</td>
                  {/*<td>{creator.statistics.clickThroughRate?.displayValue}</td>*/}
                  <td>{creator.statistics?.transactionSummary?.conversionRate?.displayValue}</td>
                  <td>{creator.statistics?.transactionSummary?.totalSales?.displayValue}</td>
                  <td>
                    <Checkbox
                      checked={creator.needsApproval}
                      onChange={(e, checked) => {
                        onCreatorNeedsApprovalChecked(creator.id, checked)
                      }}
                    />
                  </td>
                  <td>
                    {creator.needsApproval && (
                      <center>
                        <ApprovalStatusIcon
                          status={creator.approvalStatus}
                          showTitle={true}
                        />
                      </center>
                    )}
                  </td>
                  <td
                    className="summary-table-clickable"
                    onClick={() => setIsUserCardOpened(creator.id)}
                  >
                    <LaunchIcon />
                  </td>
                </tr>
              )
            })}
          </TbodyStyled>
        </table>
      </div>
    </Card>
  )
}
