import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { creatorProfileGet, userGetProfile } from '../../api/services'
import { CreatorProfileData, UserData } from '../../api/types'

interface IAuthSliceState {
  token: string
  email: string
  isEmailVerified: boolean
  isAuthenticated: boolean
  name: string
  picture: string
  profileData: UserData | null
  creatorProfileData: CreatorProfileData | null
  logout: ({ returnTo: string }) => void
}

export const getProfileDataThunk = createAsyncThunk(
  'auth/getProfileData',
  async () => {
    let profileData = await userGetProfile()
    let data = profileData.data
    return {
      profileData: data,
    }
  },
)

export const getCreatorProfileDataThunk = createAsyncThunk(
  'auth/getCreatorProfileData',
  async () => {
    let creatorProfileData = await creatorProfileGet()
    let data = creatorProfileData.data
    return {
      creatorProfileData: data,
    }
  },
)

let initialState: IAuthSliceState = {
  token: '',
  email: '',
  name: '',
  picture: '',
  isEmailVerified: false,
  isAuthenticated: false,
  profileData: null,
  creatorProfileData: null,
  logout: () => {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token
    },
    setAuthEmail(state, action: PayloadAction<{ email: string }>) {
      state.email = action.payload.email
    },
    setEmailVerification(state, action: PayloadAction<{ verified: boolean }>) {
      state.isEmailVerified = action.payload.verified
    },
    setAuthName(state, action: PayloadAction<{ name: string }>) {
      state.name = action.payload.name
    },
    setAuthPicture(state, action: PayloadAction<{ picture: string }>) {
      state.picture = action.payload.picture
    },
    setIsAuthenticated(state, action: PayloadAction<{ isAuthenticated: boolean }>) {
      state.isAuthenticated = action.payload.isAuthenticated
    },
    setLogoutFunction(state, action: PayloadAction<{ logout: ({ returnTo: string }) => void }>) {
      state.logout = action.payload.logout
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfileDataThunk.fulfilled, (state, action) => {
      state.profileData = action.payload.profileData
    })
    builder.addCase(getCreatorProfileDataThunk.fulfilled, (state, action) => {
      state.creatorProfileData = action.payload.creatorProfileData
    })
  },
})

export const {
  setAccessToken,
  setEmailVerification,
  setAuthEmail,
  setAuthPicture,
  setAuthName,
  setIsAuthenticated,
  setLogoutFunction,
} = authSlice.actions
export default authSlice.reducer
