import React, { FC, useEffect, useState } from 'react'
import {
  Badge,
  Divider,
  Grid,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { Box, MenuItem } from '@mui/material'
import { useHistory } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  notificationsAcknowledge,
  notificationsGetAll,
} from '../../../api/services'
import { BOUserNotificationData } from '../../../api/types'
import { TabContext, TabPanel } from '@mui/lab'
import Button from '@mui/material/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { toast } from 'react-toastify'
import Moment from 'react-moment'
import { experimentalStyled } from '@mui/material/styles'

const StyledTabs = experimentalStyled(Tabs)(
  () => `
    .MuiTabs-indicator {
      box-shadow: none;
      border: none;
      background-color: #424242;
    }
    .Mui-selected {
     background-color: #424242;
  }
    `,
)

export const NotificationsBox: FC<{}> = ({}) => {
  const [badgeEl, setBadgeEl] = useState(null)
  const expanded = Boolean(badgeEl)
  const [notificationsActive, setNotificationsActive] =
    useState<BOUserNotificationData[]>()
  const [notificationsAcknowledged, setNotificationsAcknowledged] =
    useState<BOUserNotificationData[]>()
  const [warning, setWarning] = useState(false)
  const [tab, setTab] = useState<string>('New')

  useEffect(() => {
    if (!notificationsActive) {
      notificationsGetAll({ status: 'Active' }).then(d => {
        let data = d.data
        setWarning(data.filter(n => n.level == 'Warning').length > 0)
        setNotificationsActive(data)
      })
    }
    if (!notificationsAcknowledged) {
      notificationsGetAll({ status: 'Acknowledged' }).then(d => {
        let data = d.data
        setNotificationsAcknowledged(data)
      })
    }
  }, [notificationsActive, notificationsAcknowledged])

  const router = useHistory()

  const clearNotification = async (notification: BOUserNotificationData) => {
    if (notification.canAcknowledge) {
      await notificationsAcknowledge(notification.id)
      notification.canAcknowledge = false
      setNotificationsActive(
        notificationsActive.filter(n => n.id !== notification.id),
      )
      setNotificationsAcknowledged(
        [notification].concat(notificationsAcknowledged).sort((a, b) => {
          return a.createdAt < b.createdAt
            ? -1
            : a.createdAt == b.createdAt
            ? 0
            : 1
        }),
      )
    }
  }

  const clearAll = async () => {
    for (const notification of notificationsActive) {
      await clearNotification(notification)
    }
    setBadgeEl(null)
    toast.success('All active notifications cleared')
  }

  const notificationRender = (notification: BOUserNotificationData) => {
    return (
      <>
        <Box key={notification.identifier}>
          <MenuItem
            onClick={e => {
              e.stopPropagation()
              if (notification.path) {
                setBadgeEl(null)
                router.push(notification.path)
              }
            }}
            sx={{
              backgroundColor:
                notification.level === 'Warning' ? '#FFF0E4' : '#ffffff',
              padding: '6px',
            }}
          >
            <Grid container alignItems={'center'}>
              <Grid item sx={{ width: '40px' }}>
                {notification.canAcknowledge && (
                  <IconButton
                    onClick={async e => {
                      e.stopPropagation()
                      await clearNotification(notification)
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography
                      variant={'h4'}
                      sx={{ whiteSpace: 'break-spaces', margin: '0px' }}
                    >
                      {notification.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant={'body1'}
                      sx={{
                        whiteSpace: 'break-spaces',
                        fontSize: '12px',
                        margin: '0px',
                      }}
                    >
                      {notification.message}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant={'body2'}
                      sx={{
                        whiteSpace: 'break-spaces',
                        color: '#969191',
                        fontSize: '10px',
                        margin: '8px 0px 0px 0px',
                      }}
                    >
                      <Moment format="DD MMM YYYY[,] HH:mm">
                        {notification.createdAt * 1000}
                      </Moment>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ width: '20px' }}>
                {notification.path && <KeyboardArrowRightIcon />}
              </Grid>
            </Grid>
          </MenuItem>
        </Box>
        <Divider variant={'middle'} sx={{ margin: '6px' }} />
      </>
    )
  }

  return (
    <>
      {notificationsActive &&
        notificationsAcknowledged &&
        notificationsActive.length > 0 && (
          <Box sx={{ marginLeft: '20px' }}>
            <IconButton onClick={e => setBadgeEl(e.currentTarget)}>
              <Badge
                badgeContent={notificationsActive.length}
                color={warning ? 'warning' : 'info'}
              >
                <NotificationsOutlinedIcon color={'primary'} />
              </Badge>
            </IconButton>
            <Popover
              open={expanded}
              anchorEl={badgeEl}
              onClose={() => setBadgeEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <TabContext value={tab}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    padding: '10px',
                  }}
                >
                  <StyledTabs
                    variant={'standard'}
                    value={tab}
                    onChange={(a, t) => setTab(t)}
                  >
                    <Tab label="New" value={'New'} />
                    <Tab label="Archived" value={'Archived'} />
                  </StyledTabs>
                </Box>
                <TabPanel value={'New'} sx={{ padding: '6px' }}>
                  <>
                    <Box sx={{ width: '360px' }}>
                      {notificationsActive.map(notification =>
                        notificationRender(notification),
                      )}
                    </Box>
                    <Button onClick={clearAll}>Clear all</Button>
                  </>
                </TabPanel>
                <TabPanel value={'Archived'} sx={{ padding: '6px' }}>
                  <Box sx={{ width: '360px' }}>
                    {notificationsAcknowledged.length > 0 &&
                      notificationsAcknowledged.map(notification =>
                        notificationRender(notification),
                      )}
                  </Box>
                </TabPanel>
              </TabContext>
            </Popover>
          </Box>
        )}
    </>
  )
}
