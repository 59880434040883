import { CircularProgress, Box, Card} from '@mui/material'
import { useEffect, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import UpperCard from 'src/components/Dashboard/UpperCard'
import {
  dashboardGetAvailableBrands,
  dashboardGetOrganizationDataByRange,
  dashboardGetOrganizationDataChartsByRange,
} from '../api/services'
import {
  BrandData, DashboardCharts,
  DashboardData, DashboardGetCreatorDataByRangeQueryParams,
} from '../api/types'
import FullList from '../components/Dashboard/FullList/FullList'
import RecentlyCard from '../components/Dashboard/RecentlyCard'
import SummaryFilter from '../components/Dashboard/SummaryFilter'
import ExperienceTable from '../components/Dashboard/Tables/ExperienceTable'
import UserChoiceTable from '../components/Dashboard/Tables/UserChoiceTable'
import {DateRangeSummaryFilter, defaultFilterDateRange} from "../components/Dashboard/DateRangeSummaryFilter";

export type TableType = 'EXPERIENCE' | 'USER' | undefined
export type FilterType = 'BRAND' | 'CREATOR' | 'TIMERANGE'

const Dashboard = () => {
  const [showFullList, setShowFullList] = useState<TableType>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [filterData, setFilterData] =
    useState<DashboardGetCreatorDataByRangeQueryParams>({
      brandId: undefined,
      ...defaultFilterDateRange
    })

  const [availableBrands, setAvailableBrands] = useState<BrandData[]>([])

  const [dashboardData, setDashboardData] = useState<DashboardData>()
  const [dashboardCharts, setDashboardCharts] = useState<DashboardCharts>()

  const getDashbardData = async () => {
    const res = await dashboardGetOrganizationDataByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
    })
    setDashboardData(res.data)
    const chartsRes = await dashboardGetOrganizationDataChartsByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
    })
    setDashboardCharts(chartsRes.data)
    setIsLoading(false)
  }

  // value any because we can't reuse type from generated types
  const handleFilterDataChange = (type: FilterType, value: any) => {
    if (type === 'TIMERANGE') {
      setFilterData({
        ...filterData,
        from: value.start,
        to: value.end,
      })
    }
    if (type === 'BRAND') {
      setFilterData({
        ...filterData,
        brandId: value,
      })
    }
  }

  const getAvailableBrands = async () => {
    const res = await dashboardGetAvailableBrands()
    setAvailableBrands(res?.data)
  }

  useEffect(() => {
    getAvailableBrands()
    getDashbardData()
  }, [filterData])

  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <Box className="dashboard" display="flex" flexDirection={{xs: 'column', md: 'row'}}>
      <Box className="sticky-left-bar" sx={{height: {xs: 'auto', md: '300px'}}} position={{xs: 'relative', md: 'sticky'}}>
        <UpperCard
          title="Account"
          fields={[
            {
              key: 'Licence',
              value: dashboardData?.accountSummary?.licenseTier,
            },

            {
              key: 'Domain integration',
              value:
                dashboardData?.accountSummary?.origins?.current?.toString(),
            },
            {
              key: 'Brands and collection',
              value: dashboardData?.accountSummary?.brands?.current?.toString(),
            },
            {
              key: 'Active experiences',
              value:
                dashboardData?.accountSummary?.activeExperiences?.current?.toString(),
            },
            {
              key: 'Subscribers',
              value:
                dashboardData?.overallStatistics?.subscribers?.displayValue,
            },
            {
              key: 'Sales',
              value:
                dashboardData?.overallStatistics?.transactionSummary?.totalSales?.displayValue,
            }
          ]}
        />
        <RecentlyCard recentlyEdited={dashboardData?.recentlyEdited} />
      </Box>

      {showFullList ? (
        <FullList
          experiences={dashboardData?.topPerformingExperiences}
          tableType={showFullList}
          setShowFullList={setShowFullList}
          userChoices={dashboardData?.topUserChoices}
        />
      ) : (
        <Card className="summary-data">
          <Box className="filters-wrapper" >
            <DateRangeSummaryFilter
              filterData={filterData}
              onSelected={(range) => {
                handleFilterDataChange('TIMERANGE', range)
              }}
            />
            <SummaryFilter
              type="BRAND"
              availableFilters={availableBrands}
              filterData={filterData}
              setFilterData={handleFilterDataChange}
            />
          </Box>
          <SummaryOverview
            overallStatistics={dashboardData?.overallStatistics} charts={dashboardCharts}
          />
          <ExperienceTable
            experiences={dashboardData?.topPerformingExperiences}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
          <UserChoiceTable
            userChoices={dashboardData?.topUserChoices}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
        </Card>
      )}
    </Box>
  )
}

export default Dashboard
