import React, {FC} from "react";
import {Autocomplete} from "@mui/material";
import {TextField} from "@mui/material";


export const DialogSimpleFieldTags: FC<{
  values: string[],
  onChange: (any) => void,
  helperText?: string,
}> = ({
  values,
  onChange,
  helperText,
}) => {

  const handleKeyDown = event => {
    switch (event.key) {
      case ',':
      case ' ':
      case 'Enter': {
        event.preventDefault()
        event.stopPropagation()
        let val = event.target.value.trim()
        if (val.length > 0 && values.indexOf(val) == -1) {
          onChange([...(values || []), event.target.value])
        }
        break
      }
      default:
    }
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={values ?? []}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      filterSelectedOptions
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown
        return (
          <TextField
            {...params}
            variant="outlined"
            label="Tags"
            placeholder="ex. Dress"
            margin="normal" helperText={helperText}
            fullWidth
          />
        )
      }}
    />
  )
}