import { CreatorStatistics } from '../../api/types'
import styled from '@emotion/styled'
import Gauge, { getGaugeColorByValue } from '../Dashboard/Templates/RoundChart'
import { Card } from '@mui/material'
import moment from "moment/moment";

interface Props {
  data: CreatorStatistics
}

const Container = styled(Card)(() => ({
  padding: '20px 20px',
  borderRadius: '15px',
  backgroundColor: '#fff',
  margin: '15px 10px 25px 15px',
}))

const InfoBlock = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const Avatar = styled.img(() => ({
  width: 100,
  height: 100,
  borderRadius: '50%',
  objectFit: 'cover',
}))

const Info = styled.div(() => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
}))

const InfoItem = styled.p(() => ({
  margin: 0,
  fontSize: '13px',
  marginBottom: '4px',
}))

const ButtonStyled = styled.a(() => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 44,
  width: '100%',
  borderRadius: '15px',
  border: '1px solid #5569FF',
  background: '#F6F8FB',
  color: '#5569FF',
  marginTop: '10px',
  cursor: 'pointer',
  '&:hover': {
    color: '#5569FF',
  },
}))

const RateComponent = styled.div(({ value }: { value: number }) => ({
  background: `${getGaugeColorByValue(value)}`,
  width: 'fit-content',
  padding: '3px 6px',
  color: '#fff',
  borderRadius: '13px',
}))

export const ProfileCard = ({ data }: Props) => {
  return (
    <Container>
      <InfoBlock>
        <Gauge
          circleRatio={0.8}
          value={data.score.current}
          width={115}
          strokeWidth={5}
          variant="SCORE"
        >
          <Avatar src={data.profilePicture} />
        </Gauge>
        <Info>
          <InfoItem>
            <b>{data.displayName}</b>
          </InfoItem>
          <InfoItem>Joined {moment(data.joined * 1000).format('yyyy-MM-DD')}</InfoItem>
          <InfoItem>
            Contester rate{' '}
            <RateComponent value={data.score.current}>
              {' '}
              {data.score.displayValue}
            </RateComponent>
          </InfoItem>
        </Info>
      </InfoBlock>
      {/*<ButtonStyled href="mailto:info@contester.net">Contact</ButtonStyled>*/}
    </Container>
  )
}
