import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  TextField,
} from '@mui/material'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import Text from '../../Text/Text'
import React, { useEffect, useState } from 'react'
import { updateProfile } from '../../../services/api/profile'
import { getProfileDataThunk } from 'src/redux/slices/authSlice'
import { useDispatch } from 'react-redux'
import { UserData } from '../../../api/types'

type Props = {
  profileData: UserData
}

const Profile: React.FC<Props> = ({ profileData }) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [firstName, setFirstName] = useState(profileData?.firstName || '')
  const [lastName, setLastName] = useState(profileData?.lastName || '')

  const updateData = async () => {
    await updateProfile({ firstName, lastName })
    dispatch(getProfileDataThunk())
    setIsEditing(false)
  }

  useEffect(() => {
    setFirstName(profileData?.firstName ?? '')
    setLastName(profileData?.lastName ?? '')
  }, [profileData])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Personal Details
              </Typography>
              <Typography variant="subtitle2">
                Manage information related to your personal details
              </Typography>
            </Box>
            {!isEditing ? (
              <Button
                variant="text"
                onClick={() => setIsEditing(true)}
                startIcon={<EditTwoToneIcon />}
              >
                Edit
              </Button>
            ) : (
              <Button onClick={updateData}>Done</Button>
            )}
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {!isEditing ? (
                    <Text color="black">
                      <b>
                        {profileData?.firstName} {profileData?.lastName}
                      </b>
                    </Text>
                  ) : (
                    <>
                      <TextField
                        style={{ marginTop: '-5px' }}
                        size="small"
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}
                        label="First name"
                      />
                      <TextField
                        style={{ marginTop: '-5px' }}
                        size="small"
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                        label="Last name"
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Email address:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{profileData?.email}</b>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Divider />
      {/*<Grid item xs={12}>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary={t('Change Password')}
                secondary={t('You can change your password here')}
              />
              <Button size="large" variant="outlined">
                {t('Change password')}
              </Button>
            </ListItem>
             <Divider component="li" />
              <ListItem sx={{ p: 3 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{
                    variant: 'subtitle2',
                    lineHeight: 1,
                  }}
                  primary={t('Two-Factor Authentication')}
                  secondary={t(
                    'Enable PIN verification for all sign in attempts',
                  )}
                />
                <Switch color="primary" />
              </ListItem> 
          </List>
        </Card>
      </Grid>*/}
    </Grid>
  )
}

export default Profile
