import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'src/redux/store'
import {
  getCreatorProfileDataThunk,
  getProfileDataThunk,
} from '../../../redux/slices/authSlice'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import CreatorProfile from './CreatorProfile'
import Profile from './Profile'
import UserInvitations from "../OrganizationData/UserInvitations";
import {useAppContext} from "../../../contexts/PermissionContext";

const UserSettings: React.FC = () => {
  const dispatch = useDispatch()

  const { permissions } = useAppContext()

  const profileData = useSelector(
    (state: RootState) => state.authReducer.profileData,
  )
  const creatorProfileData = useSelector(
    (state: RootState) => state.authReducer.creatorProfileData,
  )

  useEffect(() => {
    if (dispatch && permissions) {
      if (permissions.creator.viewProfile && permissions.creator.updateProfile && !creatorProfileData) {
        dispatch(getCreatorProfileDataThunk())
      } else {
        dispatch(getProfileDataThunk())
      }
    }
  }, [dispatch, permissions])

  return (
    <ContentWrapper title="User Details - Management">
      <UserInvitations />
      {permissions?.creator?.viewProfile && permissions?.creator?.updateProfile ? (
        <>
          {creatorProfileData && (
            <CreatorProfile creatorProfileData={creatorProfileData} />
          )}
        </>
      ) : (
        <Profile profileData={profileData} />
      )}
    </ContentWrapper>
  )
}

export default UserSettings
