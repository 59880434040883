import React, {FC, useEffect, useState} from "react";
import {
  LiveStreamDestinationData,
  LiveStreamListData,
  LiveStreamSingleData,
  LiveStreamStreamingCredentials
} from "../../api/types";
import {GridContext} from "../Reusable/InfiniteItemGrid";
import {
    liveStreamChangeAccessType,
    liveStreamsComplete,
    liveStreamsDelete,
    liveStreamsGetCredentials, liveStreamsGetDestinations,
    liveStreamsGetSingle,
} from "../../api/services";
import {toast} from "react-toastify";
import {LayoutWithSidebarWrapper, sidebarStyles} from "../Reusable/LayoutWithSidebars";
import {DialogConfirmation} from "../Reusable/DialogConfirmation";
import {LiveStreamUpdateDialog} from "./LiveStreamUpdateDialog";
import {SidebarItemSummary} from "../Reusable/SidebarItemSummary";
import {
    Checkbox,
    Divider, FormControlLabel,
    Grid, List, ListItem, ListItemText,
    Typography
} from "@mui/material";
import {Button} from "@mui/material";
import {CodeDisplay} from "../Reusable/CodeDisplay";
import {useAppContext} from "../../contexts/PermissionContext";
import {useHistory} from "react-router";
import {DialogLiveStreamDestinations} from "./DialogLiveStreamDestinations";

export const LiveStreamsSidebar: FC<{
  tabOverride?: string,
  selectedLivestream: LiveStreamListData,
  context: GridContext,
}> = ({
  tabOverride,
  selectedLivestream,
  context,
}) => {

  const { permissions } = useAppContext()
  let router = useHistory()

  const [credentials, setCredentials] = useState<LiveStreamStreamingCredentials | null>(null)
  const [destinations, setDestinations] = useState<LiveStreamDestinationData[]>()
  const [singleData, setSingleData] = useState<LiveStreamSingleData>()
  const [isAvailableToMembers, setIsAvailableToMembers] = useState(false)

  useEffect(() => {
    if (selectedLivestream) {
      setIsAvailableToMembers(selectedLivestream.accessType === 'Extended')
      if (selectedLivestream.organizationId == permissions?.overall?.organizationId && (selectedLivestream.status === 'Scheduled' || selectedLivestream.status === 'Offline')) {
        liveStreamsGetCredentials(selectedLivestream.id).then(l => setCredentials(l.data))
        liveStreamsGetDestinations(selectedLivestream.id).then(l => setDestinations(l.data.destinations))
      }
      liveStreamsGetSingle(selectedLivestream.id).then(l => setSingleData(l.data))
    }
  }, [selectedLivestream])

  // let checkLiveStreamStatus = async (id: string) => {
  //
  //   let { data } = await liveStreamsGetStreamStatus(id)
  //
  //   if (data.status === 'Online') {
  //     toast.info('Stream is online! Notifying end users and refreshing their experiences, show time!', {
  //       autoClose: 2000,
  //     })
  //     context.refreshGrid()
  //   } else {
  //     toast.info(`Stream is in status ${data.status}. Is anyone streaming?`, {
  //       autoClose: 2000,
  //     })
  //   }
  //
  // }

  let [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false)
  let [completeConfirmationVisible, setCompleteConfirmationVisible] = useState(false)
  let [editConfirmationVisible, setEditDialogVisible] = useState(false)
  let [deleteLiveStream, setDeleteLiveStream] = useState(null)
  let [editLiveStream, setEditLiveStream] = useState(null)
  const [destinationsDialogOpen, setDestinationsDialogOpen] = useState(false)

  const deleteItem = async () => {
    await liveStreamsDelete(deleteLiveStream!!.id)
    toast.success("Live stream deleted successfully")
    dismissDelete()
    context.refreshGrid()
  }


  context.create = () => {
    setEditLiveStream(null)
    setTimeout(() => {
      setEditDialogVisible(true)
    }, 10)
  }

  const initDelete = () => {
    setDeleteLiveStream(selectedLivestream)
    setDeleteConfirmationVisible(true)
  }

  const dismissDelete = () => {
    setDeleteLiveStream(null)
    setDeleteConfirmationVisible(false)
  }

  const dismissEdit = () => {
    setEditLiveStream(null)
    setEditDialogVisible(false)
  }

  const initEdit = async () => {
    let { data } = await liveStreamsGetSingle(selectedLivestream.id)
    setEditLiveStream(data)
    setTimeout(() => {
      setEditDialogVisible(true)
    }, 10)
  }

  const editItem = () => {
    if (editLiveStream) {
      toast.success("Live stream updated successfully")
    } else {
      toast.success("Live stream created successfully")
    }
    context.refreshGrid()
  }

  const initCompleteLiveStream = () => {
    setCompleteConfirmationVisible(true)
  }

  const dismissComplete = () => {
    setCompleteConfirmationVisible(false)
  }

  const completeLiveStream = () => {
    liveStreamsComplete(selectedLivestream.id).then(() => {
      toast.success("Live stream marked as completed")
      context.refreshGrid()
    })
  }

  const triggerAvailableToMembers = (available: boolean) => {
    if (available) {
      liveStreamChangeAccessType(selectedLivestream.id, 'Extended').then(() => {
        toast.success("Live stream marked as accessible to members")
        selectedLivestream.accessType = 'Extended'
        setIsAvailableToMembers(true)
      })
    } else {
      liveStreamChangeAccessType(selectedLivestream.id, 'Standard').then(() => {
        toast.success("Live stream marked as inaccessible to members")
        selectedLivestream.accessType = 'Standard'
        setIsAvailableToMembers(false)
      })
    }
  }


  return (
    <LayoutWithSidebarWrapper>
      <DialogLiveStreamDestinations
        liveStream={selectedLivestream}
        destinations={destinations}
        setDestinations={setDestinations}
        open={destinationsDialogOpen}
        setOpen={setDestinationsDialogOpen}
      />
      <DialogConfirmation
        title={"Are you sure?"}
        description={"Are you sure you want to delete this live stream?"}
        yesTitle={"Delete"}
        noTitle={"Cancel"}
        open={deleteConfirmationVisible}
        onPressYes={deleteItem}
        onPressNo={dismissDelete}
        onDismiss={dismissDelete}
      />
      <DialogConfirmation
        title={"Are you sure?"}
        description={"Are you sure you want to mark this live stream as completed?"}
        yesTitle={"Mark as completed"}
        noTitle={"Cancel"}
        open={completeConfirmationVisible}
        onPressYes={completeLiveStream}
        onPressNo={dismissComplete}
        onDismiss={dismissComplete}
      />
      <LiveStreamUpdateDialog
        liveStream={editLiveStream}
        open={editConfirmationVisible}
        onDismiss={dismissEdit}
        onSave={editItem}
      />
      {selectedLivestream && singleData && (
        <>
          <SidebarItemSummary name={selectedLivestream.title} created={selectedLivestream.createdAt}/>
          <Divider style={sidebarStyles.divider}/>

          { tabOverride !== 'creator' && (
            <>
              <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={initEdit}>Edit</Button>
              <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={initDelete}>Delete</Button>

              { singleData.canComplete && (
                <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={initCompleteLiveStream}>Mark as completed</Button>
              )}
            </>
          )}

          { permissions?.overall?.organizationCreator && (
            <>
                <Divider sx={sidebarStyles.divider}/>
                <FormControlLabel control={<Checkbox color={"primary"} checked={isAvailableToMembers} onChange={(e) => triggerAvailableToMembers(e.target.checked)}/>} label={"Allow organization members to access live room"}/>
            </>
          )}
          <Grid container flexDirection={"column"}>
            <Grid item sx={{ width: "100%" }}>
              <Divider sx={sidebarStyles.divider}/>
              { singleData.status === 'Completed' ? (
                <>
                  <Typography variant={"body1"}>
                    This stream is completed and cannot be streamed to again.
                  </Typography>
                  { singleData.recordings && singleData.recordings.length > 0 ? (
                    <>
                      <Divider sx={sidebarStyles.divider}/>
                      <Typography variant={"h4"}>
                        Streaming recordings
                      </Typography>
                      <List>
                        { singleData.recordings.map((recording, index) => (
                          <ListItem key={recording.streamedFrom}>
                            <ListItemText
                              primary={<a href={recording.recording.location} target={"_blank"}><Button>Recording {index+1}</Button></a>}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  ) : (
                    <Typography variant={"body1"}>
                      If anyone streamed, recordings will appear below within a couple hours after live stream was marked as completed.
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  { destinations && tabOverride !== 'creator' && (
                    <>
                      <Divider sx={sidebarStyles.divider}/>
                      <Typography variant={"h4"}>
                        Multicasting ({destinations.length}/5)
                      </Typography>
                      <Button sx={sidebarStyles.mainButton}
                              variant="outlined"
                              color="primary"
                              onClick={() => setDestinationsDialogOpen(true) }
                      >
                        Manage
                      </Button>
                    </>
                  )}
                  { credentials && tabOverride !== 'creator' && (
                    <>
                      <Divider sx={sidebarStyles.divider}/>
                      <Typography variant={"h4"}>
                        Streaming credentials
                      </Typography>
                      <Typography variant={"body1"} color={"secondary"}>
                        when using dedicated streaming software
                      </Typography>
                      <CodeDisplay code={credentials.rtmpsUrl}/>
                      <CodeDisplay code={credentials.rtmpsStreamKey}/>
                    </>
                  )}
                  {/*{ selectedLivestream.status !== 'Online' && (*/}
                  {/*  <>*/}
                  {/*    <Divider sx={sidebarStyles.divider}/>*/}
                  {/*    <Button sx={sidebarStyles.mainButton}*/}
                  {/*            variant="contained"*/}
                  {/*            color="primary"*/}
                  {/*            onClick={() => checkLiveStreamStatus(selectedLivestream.id)}*/}
                  {/*    >*/}
                  {/*      Check stream status*/}
                  {/*    </Button>*/}
                  {/*  </>*/}
                  {/*)}*/}

                  { permissions?.liveStream?.goLive &&
                    permissions?.liveStream?.viewLiveRoom && (
                    <>
                      <Divider sx={sidebarStyles.divider}/>
                      <Button sx={sidebarStyles.mainButton}
                              variant="contained"
                              color="success"
                              onClick={() => router.push(`/content/liveroom?id=${selectedLivestream.id}`)}
                      >
                        Live room
                      </Button>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </LayoutWithSidebarWrapper>
  )
}