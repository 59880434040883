import { Card } from '@mui/material'
import { HighlightOff } from '@mui/icons-material'
import { SetStateAction } from 'react'
import { useHistory } from 'react-router'
import { TopPerformingExperience } from '../../../api/types'
import { TableType } from '../../../pages/Dashboard'
import './SummaryTable.css'

interface Props {
  experiences: TopPerformingExperience[]
  rowsToShow: number
  setShowFullList: React.Dispatch<SetStateAction<TableType>>
  withHeading: boolean
  variant?: string
}

const ExperienceTable = ({
  experiences,
  rowsToShow,
  setShowFullList,
  withHeading,
  variant,
}: Props) => {
  const router = useHistory()

  const handleSequencePollClick = (id: string) => {
    if (variant === 'CREATOR') {
      router.push('/creators/experiences?id=' + id)
    } else {
      router.push('/content/experiences?id=' + id)
    }
  }

  return (
    <div className="summary-table-wrapper">
      <Card className="summary-table" m={2}>
        <table>
          <thead
            className={`summary-table-header ${
              !withHeading && 'sticky-header'
            }`}
          >
            {!withHeading && (
              <p
                className="table-close"
                onClick={() => setShowFullList(undefined)}
              >
                <HighlightOff />
              </p>
            )}
            <tr>
              <th>Top performing experiences</th>
              <th>Unique visitors</th>
              <th>Avg Time Spent</th>
              <th>Sales</th>
            </tr>
          </thead>
          <tbody>
            {experiences?.slice(0, rowsToShow).map(experience => {
              return (
                <tr key={experience?.id}>
                  <td
                    className="summary-table-clickable"
                    onClick={() => handleSequencePollClick(experience?.id)}
                  >
                    <img
                      className="table-avatar"
                      src={
                        experience.brand.imageLink
                      }
                    />
                    <div>
                      <div>
                        {experience?.campaignName}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {experience.brand.title}
                      </div>
                    </div>
                  </td>
                  <td>{experience?.statistics.visitors?.displayValue}</td>
                  <td>
                    {experience?.statistics.averageTimeSpent?.displayValue}
                  </td>
                  <td>
                    {experience?.statistics?.transactionSummary?.totalSales?.displayValue}
                  </td>
                </tr>
              )
            })}
          </tbody>
          {withHeading && experiences?.length > rowsToShow && (
            <tfoot>
              <tr className="table-show-more">
                <td colSpan={5} onClick={() => setShowFullList('EXPERIENCE')}>
                  Show more
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  )
}

export default ExperienceTable
