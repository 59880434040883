import React, {FC, useEffect, useState} from "react";
import DateRangePicker from "react-daterange-picker";
import 'react-daterange-picker/dist/css/react-calendar.css'
import './DateRangeSummaryFilter.css'
import {Box, Button, Grid, List, ListItemButton, Popover} from "@mui/material";

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {DashboardGetCreatorDataByRangeQueryParams} from "../../api/types";

const moment = extendMoment(Moment);

export const defaultFilterDateRange = {
  from: moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'),
  to: moment(new Date()).format('YYYY-MM-DD'),
  dateRangeLabel: 'Last 30 days'
}

export interface SelectedRange {
  start: string,
  end: string
}

export const DateRangeSummaryFilter: FC<{
  onSelected: (range: SelectedRange) => void,
  filterData: DashboardGetCreatorDataByRangeQueryParams,
}> = ({
  onSelected,
  filterData,
}) => {

  const [anchor, setAnchor] = useState(null)

  const defaultSelectedRange = () => {
    let range = moment.range(moment(filterData.from), moment(filterData.to))
    // @ts-ignore
    range.dateRangeLabel = filterData.dateRangeLabel
    return range
  }

  const [selectedRange, setSelectedRange] = useState(defaultSelectedRange())

  // @ts-ignore
  const [filterLabel, setFilterLabel] = useState<string>()

  useEffect(() => {
    let from = selectedRange.start.format('YYYY-MM-DD')
    let to = selectedRange.end.format('YYYY-MM-DD')
    // @ts-ignore
    let label = selectedRange.dateRangeLabel
    if (label) {
      setFilterLabel(label)
    } else {
      if (from === to) {
        setFilterLabel(from)
      } else {
        setFilterLabel(from + ' to ' + to)
      }
    }
  }, [selectedRange]);

  const handleSelect = (range) => {
    setSelectedRange(range)
    onSelected({
      start: range?.start?.format('YYYY-MM-DD'),
      end: range?.end?.format('YYYY-MM-DD'),
    })
    setAnchor(null)
  }

  const shortcutFilterLastDays = (fromDays: number, toDays: number, label: string) => {

    let range = moment.range(
      moment(moment(new Date()).subtract(fromDays, 'days').format('YYYY-MM-DD')),
      moment(moment(new Date()).subtract(toDays, 'days').format('YYYY-MM-DD')),
    )
    // @ts-ignore
    range.dateRangeLabel = label
    handleSelect(range)

  }

  return (
    <Box className={'summary-filter-wrapper'}>
      <Button
        sx={{ mr: 1 }}
        variant="outlined"
        color="secondary"
        onClick={(e) => setAnchor(e.currentTarget) }>
        {filterLabel}
      </Button>
      <Popover
        open={anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
        <Grid container spacing={1} sx={{width: "500px" }}>
          <Grid item>
            <DateRangePicker
              singleDateRange={true}
              firstOfWeek={1}
              minimumDate={moment(new Date()).subtract(3, 'years').toDate()}
              maximumDate={new Date()}
              numberOfCalendars={1}
              value={selectedRange}
              onSelect={handleSelect}
            />
          </Grid>
          <Grid item xs={4}>
            <List>
              <ListItemButton onClick={() => shortcutFilterLastDays(0, 0, 'Today')}>
                Today
              </ListItemButton>
              {/*<Divider/>*/}
              <ListItemButton onClick={() => shortcutFilterLastDays(1, 1, 'Yesterday')}>
                Yesterday
              </ListItemButton>
              {/*<Divider/>*/}
              <ListItemButton onClick={() => shortcutFilterLastDays(7, 0, 'Last 7 days')}>
                Last 7 days
              </ListItemButton>
              {/*<Divider/>*/}
              <ListItemButton onClick={() => shortcutFilterLastDays(30, 0, 'Last 30 days')}>
                Last 30 days
              </ListItemButton>
              {/*<Divider/>*/}
              <ListItemButton onClick={() => shortcutFilterLastDays(365, 0, 'Last 365 days')}>
                Last 365 days
              </ListItemButton>
            </List>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  )
}