import { CircularProgress } from '@mui/material'
import { Menu, MenuItem, Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import {
  dashboardGetCreatorDataByRange,
  dashboardGetOrganizationDataCreatorChartsByRange,
} from '../../api/services'
import {
  CreatorStatistics, DashboardCharts,
  DashboardData, DashboardGetCreatorDataByRangeQueryParams,
} from '../../api/types'
import styled from '@emotion/styled'
import { CreatorsTable } from 'src/components/CreatorDashboard/CreatorsTable'
import { ProfileCard } from 'src/components/CreatorDashboard/ProfileCard'
import FullList from 'src/components/Dashboard/FullList/FullList'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import ExperienceTable from 'src/components/Dashboard/Tables/ExperienceTable'
import UserChoiceTable from 'src/components/Dashboard/Tables/UserChoiceTable'
import CloseSVG from '../../assets/x.svg'
import { ProfileActivity } from 'src/components/CreatorDashboard/ProfileActivity'
import { useAppContext } from 'src/contexts/PermissionContext'
import {DateRangeSummaryFilter, defaultFilterDateRange} from "../../components/Dashboard/DateRangeSummaryFilter";
import {FilterType} from "../Dashboard";

export type TableType = 'EXPERIENCE' | 'USER' | undefined

const LoadingContainer = styled.div(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const FilterWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& img': {
    width: 40,
    height: 40,
    marginTop: 10,
    marginRight: 10,
    cursor: 'pointer',
  },
}))

const sortings = [
  {
    value: 'visitors',
    text: 'Unique visitors',
  },
  {
    value: 'timeSpent',
    text: 'Time spent',
  },
  {
    value: 'ctr',
    text: 'CTR',
  },
  {
    value: 'conversion',
    text: 'Conversion',
  },
  {
    value: 'salesValue',
    text: 'Sales value',
  },
]

export const CreatorsList = () => {

  const { getQueryParam, setQueryParam, removeQueryParam } = useAppContext()

  const [showFullList, setShowFullList] = useState<TableType>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isUserCardOpened, setIsUserCardOpened] = useState(false)
  const [sort, setSort] = useState<string>(sortings[0].text)
  const [openSort, setOpenMenuSort] = useState<boolean>(false)

  const actionRef = useRef<any>(null)

  const [filterData, setFilterData] =
    useState<DashboardGetCreatorDataByRangeQueryParams>(defaultFilterDateRange)

  const [dashboardData, setDashboardData] = useState<DashboardData>()
  const [creator, setCreator] = useState<CreatorStatistics>()
  const [dashboardCharts, setDashboardCharts] = useState<DashboardCharts>()

  useEffect(() => {

    setFilterData({
      ...filterData,
      creatorId: getQueryParam('id'),
    })
  }, [getQueryParam('id')])

  const getDashboardCreatorData = () => {
    dashboardGetCreatorDataByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData.creatorId,
    })
      .then(res => {
        setDashboardData(res.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
    dashboardGetOrganizationDataCreatorChartsByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData.creatorId,
    }).then(res => {
      setDashboardCharts(res.data)
    })
  }

  const handleFilterDataChange = (type: FilterType, value: any) => {
    setFilterData({
      ...filterData,
      from: value.start,
      to: value.end,
    })
  }

  useEffect(() => {
    getDashboardCreatorData()
  }, [filterData])

  const handleUserCard = (id: string) => {
    setIsUserCardOpened(true)
    const clickedItem = dashboardData.creatorStatistics.creators.filter(
      elem => elem.id === id,
    )[0]
    setCreator(clickedItem)
    setQueryParam('id', clickedItem.id)
  }

  useEffect(() => {
    const creatorParamId = getQueryParam('id')
    if (creatorParamId && dashboardData?.creatorStatistics?.creators) {
      const creatorData = dashboardData.creatorStatistics.creators.filter(
        elem => elem.id == creatorParamId,
      )[0]
      if (creatorData) {
        setIsUserCardOpened(true)
        setCreator(creatorData)
      }
    }
  }, [dashboardData?.creatorStatistics])

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }
  return (
    <div className="dashboard">
      {isUserCardOpened && (
        <div className="sticky-left-bar">
          <>
            <ProfileCard data={creator} />
            <ProfileActivity
              recentlyEdited={dashboardData?.recentlyEdited}
              data={creator}
            />
          </>
        </div>
      )}
      {!isUserCardOpened ? (
        <Box className="summary-data">
          <FilterWrapper className="filters-wrapper">
            <DateRangeSummaryFilter
              filterData={filterData}
              onSelected={(range) => {
                handleFilterDataChange('TIMERANGE', range)
              }}
            />
            {/* we'll use those later, don't remove the code */}

            {/*<CreatorsSearchStyled />*/}
            {/*<SortButton*/}
            {/*  size="small"*/}
            {/*  variant="outlined"*/}
            {/*  ref={actionRef}*/}
            {/*  onClick={() => setOpenMenuSort(true)}*/}
            {/*  endIcon={<ExpandMoreTwoTone fontSize="small" />}*/}
            {/*>*/}
            {/*  {sort}*/}
            {/*</SortButton>*/}
            <Menu
              anchorEl={actionRef.current}
              onClose={() => setOpenMenuSort(false)}
              open={openSort}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {sortings.map(_sort => (
                <MenuItem
                  key={_sort.value}
                  onClick={() => {
                    setSort(_sort.text)
                    // handleSort(_sort.value)
                    setOpenMenuSort(false)
                  }}
                >
                  {_sort.text}
                </MenuItem>
              ))}
            </Menu>
          </FilterWrapper>
          <CreatorsTable
            creators={dashboardData?.creatorStatistics.creators}
            setCreators={creators => {
              setDashboardData({
                ...dashboardData,
                creatorStatistics: {
                  ...dashboardData.creatorStatistics,
                  creators: creators,
                },
              })
            }}
            setIsUserCardOpened={handleUserCard}
          />
        </Box>
      ) : (
        <>
          {showFullList ? (
            <FullList
              experiences={dashboardData?.topPerformingExperiences}
              tableType={showFullList}
              setShowFullList={setShowFullList}
              userChoices={dashboardData?.topUserChoices}
            />
          ) : (
            <div className="summary-data">
              <FilterWrapper className="filters-wrapper">
                <DateRangeSummaryFilter
                  filterData={filterData}
                  onSelected={(range) => {
                    handleFilterDataChange('TIMERANGE', range)
                  }}
                />
                <img
                  src={CloseSVG}
                  alt="close"
                  onClick={() => {
                    setIsUserCardOpened(false)
                    removeQueryParam('id')
                  }}
                />
              </FilterWrapper>
              <SummaryOverview overallStatistics={creator?.statistics} charts={dashboardCharts} />
              <ExperienceTable
                experiences={dashboardData?.topPerformingExperiences}
                rowsToShow={3}
                setShowFullList={setShowFullList}
                withHeading={true}
                variant="CREATOR"
              />
              <UserChoiceTable
                userChoices={dashboardData?.topUserChoices}
                rowsToShow={3}
                setShowFullList={setShowFullList}
                withHeading={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
