import { CreatorStatistics, RecentlyEdited } from '../../api/types'
import styled from '@emotion/styled'
import { Card } from '@mui/material'
import { useHistory } from 'react-router'

interface Props {
  data: CreatorStatistics
  recentlyEdited: RecentlyEdited[]
}

const Container = styled(Card)(() => ({
  borderRadius: '15px',
  backgroundColor: '#fff',
  margin: '15px 10px 25px 15px',
}))

const ActivityBlock = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#000',
  fontSize: '20px',
  fontWeight: 'bold',
  padding: '20px 20px',
  background: '#F6F8FB',
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
}))

const ButtonStyled = styled.a(() => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 35,
  width: 'fit-content',
  borderRadius: '15px',
  border: '1px solid #5569FF',
  background: '#F6F8FB',
  color: '#5569FF',
  marginTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'normal',
  '&:hover': {
    color: '#5569FF',
  },
}))

const MainBlock = styled.div(() => ({
  padding: '20px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '15px',
  borderBottom: '1px solid #E9E5E5',
}))

const MainBlockItem = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const RecentActivity = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px 20px',
}))

const RecentActivityTitle = styled.div(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'start',
  color: '#000',
  fontSize: '20px',
  fontWeight: 'bold',
}))

const ActivityItem = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  cursor: 'pointer',
}))

export const ProfileActivity = ({ data, recentlyEdited }: Props) => {
  const router = useHistory()

  const handleSequencePollClick = (id: string, type: string) => {
    if (type == 'ITEM') router.push('/creators/experiences?id=' + id)
    if (type == 'CREATOR') router.push('/creators/experiences?creatorId=' + id)
  }

  return (
    <Container>
      <ActivityBlock>
        <span>Activity</span>
        <ButtonStyled
          onClick={() => handleSequencePollClick(data.id, 'CREATOR')}
        >
          See all{' '}
        </ButtonStyled>
      </ActivityBlock>
      <MainBlock>
        <MainBlockItem>
          <span>Subscribers</span>
          <b>{data.statistics?.subscribers?.displayValue}</b>
        </MainBlockItem>
        <MainBlockItem>
          <span>Sales</span>
          <b>{data.statistics?.transactionSummary?.totalSales?.displayValue}</b>
        </MainBlockItem>
        <MainBlockItem>
          <span>Total published </span>
          <b>{data.publishedExperiencesTotal}</b>
        </MainBlockItem>
        <MainBlockItem>
          <span>Last 30 days published</span>
          <b>{data.publishedExperiencesLast30Days}</b>
        </MainBlockItem>
      </MainBlock>
      <RecentActivity>
        <RecentActivityTitle>Recent activity </RecentActivityTitle>
        {recentlyEdited?.map(item => {
          return (
            <ActivityItem
              key={item.experienceId}
              onClick={() => handleSequencePollClick(item.experienceId, 'ITEM')}
            >
              <img className="round-avatar" src={item.image} />
              {item.campaignName}
            </ActivityItem>
          )
        })}
      </RecentActivity>
    </Container>
  )
}
