import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import {
  dashboardGetAvailableCreators,
  dashboardGetAvailableBrands,
  dashboardGetCreatorDataByRange,
  dashboardGetOrganizationDataCreatorChartsByRange,
} from '../../api/services'
import {
  BrandData,
  CreatorListData, DashboardCharts,
  DashboardData, DashboardGetCreatorDataByRangeQueryParams,
} from '../../api/types'
import FullList from '../../components/Dashboard/FullList/FullList'
import SummaryFilter from '../../components/Dashboard/SummaryFilter'
import ExperienceTable from '../../components/Dashboard/Tables/ExperienceTable'
import UserChoiceTable from '../../components/Dashboard/Tables/UserChoiceTable'
import styled from '@emotion/styled'
import UpperCard from 'src/components/Dashboard/UpperCard'
import RecentlyCard from 'src/components/Dashboard/RecentlyCard'
import GuidelinesCard from "../../components/Dashboard/GuidelinesCard";
import {FilterType} from "../Dashboard";

import {DateRangeSummaryFilter, defaultFilterDateRange} from "../../components/Dashboard/DateRangeSummaryFilter";

export type TableType = 'EXPERIENCE' | 'USER' | undefined

const LoadingContainer = styled.div(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const CreatorOverview = () => {
  const [showFullList, setShowFullList] = useState<TableType>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [dashboardCharts, setDashboardCharts] = useState<DashboardCharts>()
  const [filterData, setFilterData] =
    useState<DashboardGetCreatorDataByRangeQueryParams>({
      brandId: undefined,
      ...defaultFilterDateRange,
      creatorId: undefined,
    })

  const [availableBrands, setAvailableBrands] = useState<BrandData[]>([])

  const [availableCreators, setAvailableCreators] = useState<CreatorListData[]>()

  const [dashboardData, setDashboardData] = useState<DashboardData>()

  const getDashboardCreatorData = () => {
    dashboardGetCreatorDataByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData?.creatorId,
    })
      .then(res => {
        setDashboardData(res.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })

    dashboardGetOrganizationDataCreatorChartsByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData?.creatorId,
    }).then(d => {
      setDashboardCharts(d.data)
    })
  }

  const handleFilterDataChange = (type: FilterType, value: any) => {
    if (type === 'TIMERANGE') {
      setFilterData({
        ...filterData,
        from: value.start,
        to: value.end,
      })
    } else if (type === 'CREATOR') {

      setFilterData({
        ...filterData,
        creatorId: value,
      })
    } else if (type === 'BRAND') {
      setFilterData({
        ...filterData,
        brandId: value,
      })
    }
  }

  const getAvailableBrands = async () => {
    const res = await dashboardGetAvailableBrands({ creatorOnly: true })
    setAvailableBrands(res?.data)
  }

  const getAvailableCreators = async () => {
    const res = await dashboardGetAvailableCreators()
    setAvailableCreators(res.data)
  }

  useEffect(() => {
    getAvailableBrands()
    getAvailableCreators()
    getDashboardCreatorData()
  }, [filterData])

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }
  return (
    <div className="dashboard">
      <div className="sticky-left-bar">
        <UpperCard
          title="Usage"
          fields={[
            {
              key: 'Active accounts',
              value:
                dashboardData?.accountSummary?.creatorAccounts?.displayValue,
            },

            {
              key: 'Active experiences',
              value:
                dashboardData?.accountSummary?.creatorActiveExperiences?.displayValue,
            },
            {
              key: 'Subscribers',
              value:
              dashboardData?.overallStatistics?.subscribers?.displayValue,
            },
            {
              key: 'Sales',
              value:
              dashboardData?.overallStatistics?.transactionSummary?.totalSales?.displayValue,
            },
          ]}
        />
        <GuidelinesCard/>
        <RecentlyCard
          variant="CREATOR"
          recentlyEdited={dashboardData?.recentlyEdited}
        />
      </div>

      {showFullList ? (
        <FullList
          variant="CREATOR"
          experiences={dashboardData?.topPerformingExperiences}
          tableType={showFullList}
          setShowFullList={setShowFullList}
          userChoices={dashboardData?.topUserChoices}
        />
      ) : (
        <div className="summary-data">
          <div className="filters-wrapper">
            <DateRangeSummaryFilter
              filterData={filterData}
              onSelected={(range) => {
                handleFilterDataChange('TIMERANGE', range)
              }}
            />
            <SummaryFilter
              type="BRAND"
              availableFilters={availableBrands}
              filterData={filterData}
              setFilterData={handleFilterDataChange}
            />
            <SummaryFilter
              type="CREATOR"
              availableFilters={availableCreators}
              filterData={filterData}
              setFilterData={handleFilterDataChange}
            />
          </div>
          <SummaryOverview
            overallStatistics={dashboardData?.overallStatistics}
            charts={dashboardCharts}
          />
          <ExperienceTable
            variant="CREATOR"
            experiences={dashboardData?.topPerformingExperiences}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
          <UserChoiceTable
            userChoices={dashboardData?.topUserChoices}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
        </div>
      )}
    </div>
  )
}
