import React, {FC, useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";

export interface ContesterDataTableNumberedList {
  content: any[]
  page: number
  perPage: number
  totalElements: number
  totalPages: number
}

export interface ContesterDataTableState {
  columns: string[],
  fetch: (page: number, perPage: number, sortColumn: string, sortDirection: string) => Promise<ContesterDataTableNumberedList>,
  page: number,
  perPage: number,
  sortColumn: string,
  sortDirection: string,
  count: number,
  data: any[],
}

export const ContesterDataTable: FC<{
  state: ContesterDataTableState,
  setState: React.Dispatch<React.SetStateAction<ContesterDataTableState>>,
}> = ({
  state,
  setState,
}) => {

  const getData = () => {
    state.fetch(state.page, state.perPage, state.sortColumn, state.sortDirection).then(d => {
      setState({
        ...state,
        page: d.page,
        perPage: d.perPage,
        count: d.totalElements,
        data: d.content,
      })
    })
  }

  useEffect(() => {
    getData()
  }, [state.page, state.perPage, state.sortColumn, state.sortDirection])


  return (
    <MUIDataTable
      data={state.data}
      columns={state.columns}
      options={{
        filter: true,
        filterType: 'dropdown',
        responsive: 'vertical',
        serverSide: true,
        count: state.count,
        rowsPerPage: state.perPage,
        rowsPerPageOptions: [10, 25, 100],
        sortOrder: { },
        onTableChange: (action, tableState) => {
          console.log(action, tableState);

          switch (action) {
            case 'changePage':
              setState({
                ...state,
                page: tableState.page
              })
              break;
            case 'sort':
              setState({
                ...state,
                sortColumn: tableState.sortOrder.name,
                sortDirection: tableState.sortOrder.direction,
              })
              break;
            case 'changeRowsPerPage':
              setState({
                ...state,
                perPage: tableState.rowsPerPage
              })
              break;
            default:
              console.log(`action ${action} not handled.`);
          }
        },
      }}
    />
  )
}